
import React from 'react'; //,{Component}
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
//import '../App.css';

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Creado por © 2020 <a href="https://www.procesogeek.com/" target="_blank" rel="noopener noreferrer">Proceso Geek</a>. </span>
      </div>
    </footer>
  )
}

export const NewItem= ({title, action, title_right, action_right, icon_right}) => {
  return(
    <div className="page-header flex-wrap">
      <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
        <button type="button" className="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={action}>
        <i className="mdi mdi-plus-circle"></i> {title} </button>
      </div>
      {
        title_right &&
        <div className="header-right d-flex flex-wrap mt-2 mt-sm-0">
          <button type="button" style={{backgroundColor: "#1669b4"}} className="btn btn-primary mt-2 mt-sm-0 btn-icon-text" onClick={action_right}>
          {icon_right &&
              <i style={{backgroundColor: "#1D3657"}} className= {" mdi " +icon_right} ></i>
          }
           {title_right}  </button>
        </div>
      }
    </div>
  )
}

//
//props
export class CustomInput extends React.Component {
  state={
    showPassword: false,
  }
  static defaultProps = {
      type: "text"
    };
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChangeText: PropTypes.func.isRequired,
    required: PropTypes.bool,
  };

  handleClickShowPassword = () => {
    this.setState(prevstate => ({ showPassword: !prevstate.showPassword }));
  };

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  focus() {
    this._inputElement.focus()
  }

  render (){
    const { name, label, value, onChangeText, required, type, help, fullWidth, multiline} = this.props;
    let {showPassword} = this.state;
    return (
      <TextField
        required={required}
        type={type!='password'? type : (showPassword? 'text' : type) }
        id={name}
        name={name}
        label={label}
        value={value}
        helperText={help  || ""}
        InputLabelProps={{
          shrink: true
        }}
        variant="outlined"
        onChange={event => onChangeText(event)}
        inputRef={element => this._inputElement = element}
        multiline={multiline || false}
        rows={multiline? 4 : 1}
        className="inputElement inputWide"
        fullWidth={fullWidth? true : false}
        InputProps= {{
          endAdornment:
               type==='password' ?
               <InputAdornment position="end">
                 <IconButton
                   aria-label="toggle password visibility"
                   onClick={this.handleClickShowPassword}
                   onMouseDown={this.handleMouseDownPassword}
                 >
                   {showPassword ? <Visibility /> : <VisibilityOff />}
                 </IconButton>
               </InputAdornment> : null
         }}

      />
    )
  }
}



export class ComboBox  extends React.Component {
  static propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChangeText: PropTypes.func.isRequired,
    required: PropTypes.bool,
    items:  PropTypes.array,
  };

  focus() {
    this._inputElement.focus()
  }

  render (){
    const {name, label, value, onChangeText, required, items} = this.props;
    return (
      <FormControl className='selectElement'>
        <InputLabel shrink id="demo-simple-select-placeholder-label-label">
          {label}
        </InputLabel>
        {
        required?
        <Select
          native
          onChange={event => onChangeText(event)}
          value={value || ''}
          id={name}
          name={name}
          inputProps={{
            id: {name},
            required: 'required'
          }}
          ref={element => this._inputElement = element}
        >
          <option value="">Seleccione</option>
          {
            items.map((item,index)=>
                value===item.id ?
                <option key={item.id} value={item.id} selected>{item.nombre}</option>:
                <option key={item.id} value={item.id} >{item.nombre}</option>
            )
          }
        </Select>
        :
        <Select

          native
          onChange={event => onChangeText(event)}
          value={value  || ''}
          id={name}
          name={name}
          inputProps={{
            id: {name},
          }}
          ref={element => this._inputElement = element}
        >
          <option value="">Seleccione</option>
          {
            items.map((item,index)=>
                value===item.id ?
                <option key={item.id} value={item.id} selected>{item.nombre}</option>:
                <option key={item.id} value={item.id} >{item.nombre}</option>
            )
          }
        </Select>
        }
      </FormControl>
    )
  }

}
