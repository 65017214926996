import {
    USER_DATA, API,
} from "./types";

export const setUserData = (user) => {
    return {
        type: USER_DATA,
        payload: user
    }
}

export const setApiUsed = (api) => {
    return {
        type: API,
        payload: api
    }
}
