import React,{Component} from 'react';
import { withStyles } from "@material-ui/core/styles";
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';
import WebApi from '../../../components/helpers/WebApi';
import {getModalStyle} from  '../../../components/helpers/Constantes';
import {CustomInput, ComboBox} from '../../../components/common/Parts';
import Spinner from '../../../components/common/Spinner';

// CommonJS
//const Swal = require('sweetalert2');

const useStyles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  modal: {
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
   },
   paper: {
       position: 'absolute',
       width: theme.spacing(100),
       backgroundColor: theme.palette.background.paper,
       boxShadow: theme.shadows[5],
       padding: theme.spacing(2, 4, 4),
       borderRadius:10,

   },
 });




const emailRegex  =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

class UserForm extends Component {
  state={
    id:"",
    nombre:"",
    apellido:"",
    direccion:"",
    cedula:"",
    telefono:"",
    pais:0,
    contrasenia:'',
    permiso: 0,
    mensaje:"",
    paises:[],
    datos_usuario: [],
    permisos: [],
    email:'',
    show_loader:false,

  }

  USER=this.props.item || false;

  componentDidMount(){
    this.getUser();
    this.handlePaises();
    this.handlePermisos();
  }



  render(){
    const {user, classes, hideModal, open}= this.props;
    const modalStyle = getModalStyle();
    return(
      <Modal
        disablePortal
        onBackdropClick={hideModal}
        open={open}
        onClose={hideModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>

          <CloseIcon onClick={hideModal} className={'cerrar_modal'}/>

          <form action="#!" onSubmit={this.handleSubmmit}>
            {this.state.mensaje!=="" && <div className="alert alert-info">{this.state.mensaje}</div>}
              <Spinner visible={this.state.show_loader}/>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <CustomInput
                  name={"nombre"}
                  label={"Nombre"}
                  onChangeText={this.handleChange}
                  value={this.state.nombre}
                  required
                />
                <CustomInput
                  name={"apellido"}
                  label={"Apellido"}
                  onChangeText={this.handleChange}
                  value={this.state.apellido}
                  required
                />
                <CustomInput
                  name={"cedula"}
                  label={"Cédula"}
                  onChangeText={this.handleChange}
                  value={this.state.cedula}
                  required
                />
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <CustomInput
                  name={"direccion"}
                  label={"Dirección"}
                  onChangeText={this.handleChange}
                  value={this.state.direccion}
                  required
                />
                <CustomInput
                  name={"telefono"}
                  label={"Telefono"}
                  onChangeText={this.handleChange}
                  value={this.state.telefono}
                  required
                />
                <CustomInput
                  type="email"
                  name={"email"}
                  label={"Email"}
                  onChangeText={this.handleChange}
                  value={this.state.email}
                  required
                />
              </Grid>
              <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
              >
                <ComboBox
                  items={this.state.paises}
                  name={"pais"}
                  label={"Pais"}
                  onChangeText={this.handleChange}
                  value={this.state.pais}
                  required
                />
                <ComboBox
                  items={this.state.permisos}
                  name={"permiso"}
                  label={"Permiso de Acceso"}
                  onChangeText={this.handleChange}
                  value={this.state.permiso}
                  required
                />

                <CustomInput
                  type="password"
                  name={"contrasenia"}
                  label={"Contraseña (opcional)"}
                  onChangeText={this.handleChange}
                  value={this.state.contrasenia}
                />

              </Grid>
              <div className={classes.root}>
                <Button variant="contained" color="primary" type="submit" >Guardar</Button>
                {hideModal &&  <Button variant="contained" onClick={hideModal}>Cancelar</Button>}
                {this.USER && this.USER!==user.id &&
                  <Button variant="contained" color="secondary" onClick={this.alertConfirm}>Eliminar</Button>}
                {!this.USER && <Button variant="contained" onClick={this.resetForm}>Limpiar</Button>}
              </div>
          </form>
        </div>
      </Modal>
    );
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    });
  }



  handleSubmmit = (e) => {
    e.preventDefault();
    let { nombre, apellido, email, direccion, cedula, telefono,
          pais, permiso, contrasenia} =this.state;
    if(nombre.trim() < 2 ) {
      alert("Escriba el nombre");
      return;
    }
    if(apellido.trim() < 2 ) {
      alert("Escriba el apellido");
      return;
    }
    if(email.trim() < 2 || !emailRegex.test(email)) {
      alert("Escriba un email válido");
      return;
    }
    if(cedula.trim() < 2 ) {
      alert("Escriba la cedula");
      return;
    }
    if(telefono.trim() < 2 ) {
      alert("Escriba el teléfono");
      return;
    }

    if(pais.trim() < 1 ) {
      alert("Escoja la pais");
      return;
    }
    if(permiso.trim() < 1 ) {
      alert("Escoja el permiso de acceso");
      return;
    }

    this.setState({show_loader:true});
      new WebApi().editProfile( this.USER, nombre, apellido, email, direccion, cedula, telefono,
            pais, permiso, contrasenia).then(result => {
        setTimeout(() => {
            this.processSetUser(result);
            typeof this.props.handleGetUsers !== "undefined" && this.props.handleGetUsers()
            typeof this.props.hideModal !== "undefined" && this.props.hideModal()
        }, 300)
    })
  }

  processSetUser = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
      });

    } else {
        alert(data.message);
    }
  }

  getUser = () => {

    if(!this.USER){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().getUser(this.USER).then(result => {
      setTimeout(() => {
          this.processGetUserResponse(result);
      }, 300)
    })
  }

  processGetUserResponse = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        //se redirecciona automaticamente
        data=data.data;
        this.setState({
          id:data.id,
          nombre: data.nombre,
          apellido: data.apellido,
          email: data.email,
          direccion: data.direccion,
          cedula: data.cedula,
          telefono: data.telefono,
          pais: data.pais,
          permiso: data.permiso,
        })

    } else {
        alert(data.message);
    }
  }

  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteUser();
          /*
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        */
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }

  deleteUser = () => {
    //return;
    if(!this.USER){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().deleteUser(this.USER).then(result => {
        this.processDeleteUser(result);
    })
  }

  processDeleteUser = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      typeof this.props.handleGetUsers !== "undefined" && this.props.handleGetUsers()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    }
  }

  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      apellido: "",
      email: "",
      direccion: "",
      cedula: "",
      telefono: "",
      parroquia: 0,
      canton: 0,
      pais: 0,
      permiso: 0,
      mensaje: "",
      contrasenia:'',
    })
  }

  handlePaises = () => {
      //console.log(this.state);
      new WebApi().getAllPaises().then(result => {
        //setTimeout(() => {
            this.processPaises(result);
        //}, 300)
      })
  }

  processPaises = (result) => {
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          this.setState({
            paises: data.paises
          });
      } else {
          alert(data.message);
      }
  }

  handlePermisos = () => {
    new WebApi().getPermissions().then(result => {
      //setTimeout(() => {
          this.processPermisos(result);
      //}, 300)
    })
  }

  processPermisos = (result) => {
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          this.setState({
            permisos: data.permisos
          });
      } else {
          alert(data.message);
      }
  }

}

const mapStateToProps = (state) => {
    return {
        user: state.user_data.user
    }
}
const actions = {};

export default connect(mapStateToProps, actions)(withStyles(useStyles, { withTheme: true })(UserForm));
