import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Image from 'material-ui-image';
import Grid from '@material-ui/core/Grid';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://www.xperting.club/">
        www.xperting.club
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
}));

const social =  [

    { name: 'Instagram', icon: InstagramIcon , url: 'https://www.instagram.com/xperting'},
    { name: 'Twitter', icon: TwitterIcon , url: 'https://www.twitter.com/Xpertingclub'},
    { name: 'Facebook', icon: FacebookIcon , url: 'https://www.facebook.com/xperting'},
    { name: 'YouTube', icon: YouTubeIcon , url: 'https://www.youtube.com/channel/UCwUNS9e8DMFd0sTarCje24A'},
    { name: 'LinkedIn', icon: LinkedInIcon , url: ''},
];

export default function Footer(props) {
  const classes = useStyles();
  const { description } = props; //, title

  return (
    <footer className={classes.footer}>
      <Grid item xs={12} >
          <Image src="https://www.xperting.club/wp-content/uploads/2020/04/logotipo_new-05-300x65.png" style={{paddingTop:20, textAlign:"center"}} imageStyle={{width:200, height:45, position:"relative"}} className='imgFooter'  />
      </Grid>
      <Container maxWidth="lg">
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          {description}
        </Typography>
        <Copyright />
      </Container>
      <Container maxWidth="lg">
        <Grid container direction="row" spacing={1} alignItems="center"  justify='center' style={{marginTop:20}}>
        {social.map((network) => (
          <Link display="block" variant="body1" href="#" key={network.name} style={{marginLeft:20}}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <network.icon />
              </Grid>
              <Grid item>{network.name}</Grid>
            </Grid>
          </Link>
        ))}
        </Grid>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string,
};
