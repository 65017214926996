import React from 'react';
import Grid from '@material-ui/core/Grid';
import Draggable, {DraggableCore} from 'react-draggable'; // Both at the same time
import Typography from '@material-ui/core/Typography';
import GamesIcon from '@material-ui/icons/Games';

import { makeStyles, useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    design: {
      paddingLeft: 20
    },
    actionButton: {

    },
    draggable: {
      padding:20,
      border: '1px solid',
    },
    draggableIcon: {
      float: 'right'
    }
}));



export default function DesignArea(props){

    const classes = useStyles();

    const handleStart = () =>{

    }
    const handleDrag =(index, data)=> {
      onChange(data, index);
    }

    const onChange = (indexParent, data) => {
      const newData = props.elements.map((d, index) => {
          if (index === indexParent) {
            d.x= data.x;
            d.y= data.y;
          }
          return d;
      });
      props.setElements([...newData]);
    };

    const handleStop =(e, data)=>{
      console.log('coordenadas@@@ ', data);
    }



    return (
    <React.Fragment>
      <Grid item md={9} className={classes.design}>
          <Typography variant="h3">
             Design Area
          </Typography>

          { props.elements.map((d, index)=>{
            return (
            <Draggable
                key={index}
                handle=".handle"
                defaultPosition={{x: 0, y: 0}}
                grid={[25, 25]}
                position={{x: d.x, y: d.y }}
                scale={1}
                onStart={handleStart}
                onDrag={(e, data)=>handleDrag(index, data)}
                onStop={handleStop}
              >
                <div className={classes.draggable}>
                    <div className="handle"><GamesIcon className={classes.draggableIcon}/></div>
                 {
                   d.tipo==="text" ?
                     <input name={"campo_"+index} value={d.value}/>
                   :
                   <p> hola </p>
                 }
                 </div>
              </Draggable>
            )
          })
        }
      </Grid>
    </React.Fragment>
  )
}
