import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import Pagination from '@material-ui/lab/Pagination';
import { Alert } from '@material-ui/lab';
import WebApi from '../../../components/helpers/WebApi';
import Spinner from '../../../components/common/Spinner';
import Table from '../../../components/common/Table';
import UserForm from './UserForm';

const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

class Users extends Component {
  PAGE_NUM=1;
  state ={
      search : '',
      users: [],
      total: 0,
      perPage: 0,
      item_selected: false,
      showEdit:false,
      show_loader: false,
      columns: ['nombre','id','telefono','ubicacion','direccion'],
      tableHead: ["Nombre", "Id", "Teléfono", "Ubicación", "Dirección"],
      mensaje:'',
  }

  componentDidMount(){
    this.handleGetUsers();
  }

  handleChange = (e) => {
      this.setState({
          [e.target.id]: e.target.value
      })

  }

  handleSubmit = (e) => {
    this.PAGE_NUM=1;
      e.preventDefault();
      this.setState({
          [e.target.id]: e.target.value
      }, () => this.state.search.length >2 || this.state.search.length ===0  ? this.handleGetUsers(): null);

  }

  handleGetUsers = () => {
    console.log('search: ', this.state.search);
      this.setState({show_loader:true});
      new WebApi().getUsers(this.state.search, this.PAGE_NUM).then(result => {
            this.processGetUsersResponse(result);
      })
  }

  processGetUsersResponse = (result) => {
      if (result.no_result) {
          return
      }
      this.setState({show_loader:false});
      let { data } = result;
      if (data.result) {

          this.setState({total: data.total, users: data.usuarios, perPage: data.perPage});
      } else {
          this.setState({mensaje:data.message});
      }
  }

   filtrarDatos= (list, columns) =>{
      return list.map (elem => {
        return columns.map(column=> {
          if(typeof elem[column]!== 'undefined'){
            return elem[column]
          }
        });
      });
}

/*var result=  list.map(elem => (
    {
      'nombre': elem.nombre,
      'id': elem.id,
      'telefono': elem.telefono,
      'ubicacion': elem.ubicacion,
      'direccion': elem.direccion
    }
  ));
 return result; */

  content = () => {
    //let adjacents  = 4; //brecha entre páginas después de varios adyacentes
    //let offset = (this.PAGE_NUM - 1) * this.state.perPage;
    let total_pages = Math.ceil(this.state.total/this.state.perPage);
    let {tableHead, users, total, columns} = this.state;
    return (
        <React.Fragment>
          <Spinner visible={this.state.show_loader}/>
          {users.length > 0 ?
            <Table
              tableHeaderColor="primary"
              tableHead={tableHead}
              tableData={users}
              edit={this.editUser}
              columns={columns}
            />
            :
            <Typography color="textSecondary" align="center">
              No hay resultados para mostrar
            </Typography>
          }
          <Pagination count={total_pages} page={this.PAGE_NUM} color="primary" onChange={this.load_next} style={{marginTop:20}}/>
          { users.length > 0 &&
            <div style={{ textAlign:"right",marginTop: 30 }}>Total de usuarios: {total}</div>
          }
        </React.Fragment>
      )
  }

  //editUser = (item)=> this.setState({item_selected:item.id, showEdit: true});
  editUser = (id)=>  this.setState({item_selected:id, showEdit: true});


  hideModal = () => {
    this.setState({
      showEdit:false,
    })
  }

  render (){

    const { classes } = this.props;
    return (
      <React.Fragment>
      {this.state.mensaje!=="" && <Alert severity="info" style={{marginBottom:20}}>{this.state.mensaje}</Alert>}
      {!this.state.showEdit ?
        <Paper className={classes.paper}>

            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>

              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <SearchIcon className={classes.block} color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      placeholder="Busca por  email , nombre, o  UID de usuario"
                      InputProps={{
                        disableUnderline: true,
                        className: classes.searchInput,
                      }}
                      name='search'
                      id='search'
                      onChange={this.handleSubmit}
                    />
                  </Grid>
                  <Grid item>

                    <Button variant="contained" color="primary" onClick={()=>this.setState({showEdit:true, item_selected: ''}) } className={classes.addUser}>
                      Agregar Usuario
                    </Button>
                    <Tooltip title="Reload" onClick={this.refresh}>
                      <IconButton>
                        <RefreshIcon className={classes.block} color="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>

          <div className={classes.contentWrapper}>
            { this.content()}
          </div>
        </Paper>
        :
        <UserForm item={this.state.item_selected} open={this.state.showEdit} hideModal={this.hideModal} handleGetUsers={this.handleGetUsers}/>
      }
      </React.Fragment>


    )
  }
  //<Paginate tpages={total_pages}  page={this.PAGE_NUM} adjacents={adjacents} load_next={this.load_next}/>

  refresh = () => {
    this.PAGE_NUM=1;
    this.setState({
      search:'',
      },this.handleGetUsers()
    )

  }

  load_next = (event,page) => {
    this.PAGE_NUM= page;
    this.handleGetUsers();
  }

}


export default withStyles(styles)(Users);

/*
<!--<a href="#!" className="forgot-password-link">Forgot password?</a> -->
<!--<p className="login-wrapper-footer-text">Don't have an account? <a href="#!" className="text-reset">Register here</a></p>-->
*/
