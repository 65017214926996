import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import WebApi from '../../../components/helpers/WebApi';
import CursoForm from './CursoForm';
import CloseIcon from '@material-ui/icons/Close';
import Capitulos from './Capitulos';
import CursoPresentacion from './CursoPresentacion';

import { connect } from 'react-redux';

const styles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  modal: {
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
   },
   paper: {

       width: '100%',
       backgroundColor: theme.palette.background.paper,
       boxShadow: theme.shadows[5],
       padding: theme.spacing(2, 4, 4),
       borderRadius:10,
   },
  button: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
});

function getSteps() {
  return ['Datos del curso', 'Clases y secciones', 'Finalizar'];
}



class CursoCreate extends React.Component {
  constructor(props) {
    super(props);
    // crear ref
    this.child = React.createRef();
    this.child2 = React.createRef();
    this.child3 = React.createRef();
  }
  state = {
    activeStep: 0,
    completed: new Set(),
    skipped: new Set(),
  };
  ITEM=this.props.item || false;


  getStepContent=(step) => {
    switch (step) {
      case 0:
        return <CursoForm item={this.props.item} ref={element => {this.child = element}}/>;
      case 1:
        return <Capitulos item={this.props.item} ref={element => {this.child2 = element}}/>;
      case 2:
        return <CursoPresentacion item={this.props.item} ref={element => {this.child3 = element}}/>;
      default:
        return 'Unknown step';
    }
  }

  totalSteps = () => getSteps().length;

  //isStepOptional = step => step === 1;

  isStepOptional = step => {return false};

  handleSkip = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });
  };

  handleNext = () => {
    let activeStep;

    if (this.isLastStep() && !this.allStepsCompleted()) {
      // It's the last step, but not all steps have been completed
      // find the first step that has been completed
      const steps = getSteps();
      activeStep = steps.findIndex((step, i) => !this.state.completed.has(i));
    } else {
      activeStep = this.state.activeStep + 1;
    }
    this.setState({
      activeStep,
    });
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleStep = step => () => {
    this.setState({
      activeStep: step,
    });
  };

  handleComplete = () => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const completed = new Set(this.state.completed);
    completed.add(this.state.activeStep);
    this.setState({
      completed,
    });

    /**
     * Sigh... it would be much nicer to replace the following if conditional with
     * `if (!this.allStepsComplete())` however state is not set when we do this,
     * thus we have to resort to not being very DRY.
     */
    if (completed.size !== this.totalSteps() - this.skippedSteps()) {
      this.handleNext();
    }
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
      completed: new Set(),
      skipped: new Set(),
    });
  };

  skippedSteps() {
    return this.state.skipped.size;
  }

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  isStepComplete(step) {
    return this.state.completed.has(step);
  }

  completedSteps() {
    return this.state.completed.size;
  }

  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps() - this.skippedSteps();
  }

  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }

  render() {
    const {user, classes, hideModal, open}= this.props;

    return (
        <div  className={classes.paper}>
          <CloseIcon onClick={hideModal} className={'cerrar_modal'}/>
          {this.Stepper_contain()}
        </div>

    );
  }

  Stepper_contain =()=> {
    const { classes, user } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;
    return(
      <React.Fragment>
        <Stepper alternativeLabel nonLinear activeStep={activeStep}>
          {steps.map((label, index) => {
            const props = {};
            const buttonProps = {};
            if (this.isStepOptional(index)) {
              buttonProps.optional = <Typography variant="caption">Optional</Typography>;
            }
            if (this.isStepSkipped(index)) {
              props.completed = false;
            }
            return (
              <Step key={label} {...props}>
                <StepButton
                  onClick={this.handleStep(index)}
                  completed={this.isStepComplete(index)}
                  {...buttonProps}
                >
                  {label}
                </StepButton>
              </Step>
            );
          })}
        </Stepper>
        <div>
          {this.allStepsCompleted() ? (
            <div>
              <Typography className={classes.instructions}>
                All steps completed - you&apos;re finished
              </Typography>
              <Button onClick={this.handleReset}>Reset</Button>
            </div>
          ) : (
            <div>
              <Typography className={classes.instructions}>{this.getStepContent(activeStep)}</Typography>
              <div>
                <Button
                  disabled={activeStep === 0}
                  onClick={this.handleBack}
                  className={classes.button}
                >
                  Atrás
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  className={classes.button}
                >
                  Siguiente
                </Button>
                {
                  this.isStepOptional(activeStep) &&
                  !this.state.completed.has(this.state.activeStep) && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleSkip}
                      className={classes.button}
                    >
                      Skip
                    </Button>
                  )
                }
                {activeStep !== steps.length &&
                  (this.state.completed.has(this.state.activeStep) ? (
                    <Typography variant="caption" className={classes.completed}>
                      Paso {activeStep + 1} completado
                    </Typography>
                  ) : (
                    <Button variant="contained" color="primary" onClick={this.handleComplete}>
                      {this.completedSteps() === this.totalSteps() - 1 ? 'Finalizar' : 'Paso completado'}
                    </Button>
                  ))}

                  {this.ITEM && this.ITEM!=user.id &&
                    <Button style={{float:'right'}} variant="contained" color="secondary" onClick={this.alertConfirm}>Elimina este curso</Button>}
              </div>
            </div>
          )}
        </div>
      </React.Fragment>
    )
  }

  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteCurso();
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }

  deleteCurso = () => {
    //return;
    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().deleteCurso(this.ITEM).then(result => {
        this.processdeleteCurso(result);
    })
  }

  processdeleteCurso = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      typeof this.props.handleGetUsers !== "undefined" && this.props.handleGetUsers()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    } else {
      this.child.setMessage(data.message);
      this.child2.setMessage(data.message);
    }
  }

}

const mapStateToProps = (state) => {
    return {
        user: state.user_data.user
    }
}
const actions = {};


CursoCreate.propTypes = {
  classes: PropTypes.object,
};

export default connect(mapStateToProps, actions)(withStyles(styles, { withTheme: true })(CursoCreate));
