import React,{Component} from 'react';
import {Switch, Route, BrowserRouter} from 'react-router-dom';
import { connect } from 'react-redux';
import { setUserData, setApiUsed } from './redux/actions/ActionsUserData';
import MyStorage from './helpers/MyStorage';
//react-router
//Public
//import Main from '../screens/Public/Main';
import Inicio from '../screens/Public/Inicio/Index';
import Noticia from '../screens/Public/Noticia/Noticia';
import Validar from '../screens/Public/Validar';
import Membresia from '../screens/Public/Membresia';
import Exito from '../screens/Public/Pagos/Exito';
import DescargarCerts from '../screens/Public/Descargas/Certificados';

//Private
import Blog from '../screens/Private/Blog';
import BlogContent from '../screens/Private/BlogContent';
import Detalle from '../screens/Private/Curso/Detalle';
import Clase from '../screens/Private/Curso/Clase';
import Instructor from '../screens/Private/Instructor';

//admin
import Paperbase from '../screens/Admin/Paperbase';
import Users from '../screens/Admin/Users/Index';

import Destinatarios from '../screens/Admin/Users/Destinatarios';
import Inscritos from '../screens/Admin/Users/Inscritos';
import Cursos from '../screens/Admin/Curso/Index';
import User from '../screens/Admin/Users/User';
import Profile from '../screens/Admin/Users/Profile';
import Tools from '../screens/Admin/Tools';
import Certificados from '../screens/Admin/Certificados';
import Editor from '../screens/Admin/Certificados/Editor';



class Routes extends Component {

    componentDidMount() {
        this.checkLogin();
        this.checkApi();
    }

    render(){

      if (this.props.user && this.props.user.user) {
        return (
            <BrowserRouter  basename={"/"}>
              <Switch >
                //public
                <Route exact path= '/'  component={() => <Blog content={<BlogContent/>}/> }/>
                <Route path= '/curso/:alias' component={Detalle}/>
                <Route path= '/clase/:alias' component={Clase}/>
                <Route path= '/noticia/:id' component={Noticia}/>
                <Route path= '/instructor' component={Instructor}/>
                <Route path= '/membresia' component={Membresia}/>
                <Route path= '/pagoexitoso' component={Exito}/>
                <Route path= '/descargas/certificados' component={DescargarCerts}/>
                <Route path= '/validar/:code' component={Validar} />
                {//Admin page
                }

                <Route path= '/dashboard/user' component={User}/>
                <Route path= '/dashboard/perfil' component={Profile}/>
                <Route path= '/dashboard/destinatarios' component={() => <Paperbase content={<Destinatarios/>}  /> }  />
                <Route path= '/dashboard/users' component={() => <Paperbase content={<Users/>}  /> }  />
                <Route path=  '/dashboard/cursos' component={() => <Paperbase content={<Cursos/>}  /> }  />
                <Route path= '/dashboard/certificados' component={() => <Paperbase content={<Certificados/>}  /> }  />
                <Route path= '/dashboard/editor' component={() => <Paperbase content={<Editor/>}  /> }   />
                <Route path= '/dashboard/tools' component={() => <Paperbase content={<Tools/>}  /> }  />
                <Route path= '/dashboard' component={() => <Paperbase /> }  />
              </Switch >
            </BrowserRouter>
        )
        //<Route path= '/perfil/:id' component={Profile}/> with params
        //https://riptutorial.com/es/react-router/example/30227/enrutamiento-con-parametros-escritos-
      } else {
        //not loggin
        return (
          <BrowserRouter basename={"/"}>
            <Switch >
              <Route exact path= '/' component={Inicio}/>
              <Route path= '/validar/:code' component={Validar} />
              <Route path= '/noticia/:id' component={Noticia}/>
              <Route path= '/instructor' component={Instructor}/>
              <Route path= '/membresia' component={Membresia}/>
              <Route path= '/pagoexitoso' component={Exito}/>
              <Route path= '/descargas/certificados' component={DescargarCerts}/>
            </Switch >
          </BrowserRouter>
        )
      }
    }


    checkLogin = () => {
        new MyStorage().getUserInfo().then(user => {
            if (user) {
                user = JSON.parse(user);
                this.props.setUserData(user);
            } else {
            }
        });
    }

    checkApi = () => {
      new MyStorage().getApiInfo().then(api => {
        if (api) {
            this.props.setApiUsed(api);
        } else {
        }
      });
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user_data,
    }
}

const actions = {
    setUserData,
    setApiUsed
}


export default connect(mapStateToProps, actions)(Routes);
