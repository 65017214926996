import React,{useEffect} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import WebApi from '../helpers/WebApi';
//import DraftsIcon from '@material-ui/icons/Drafts';
//import SendIcon from '@material-ui/icons/Send';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const style = {
  titleMenu :{textTransform:'none', fontSize: 18, fontWeight: 'bold', color:'#c33764', }
}

export default function Categorias() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [categorias, setCategorias] = React.useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getCategorias = () => {
    new WebApi().getAllCategorias().then(result => {
      processGetCategorias(result);
    })
  }

  const processGetCategorias = (result) => {
    setCategorias(''); //BORRAR DATOS
    if (result.no_result) {
        return
    }
    let { data } = result;
    if(data.result){
      setCategorias(data.categorias);
    }
  }

  useEffect(() => {
     getCategorias();
 },[0]);

  return (
    <div>
      <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        onClick={handleClick}
        style={style.titleMenu}
      >
        Aprende
        <ListItemIcon style={{minWidth: 40, }}>
          <ExpandMoreIcon fontSize="small" style={{color:'#c33764'}}/>
        </ListItemIcon>
      </Button>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          categorias.length && categorias.map((text, index) => (
            <StyledMenuItem key={text.id}>
              <ListItemText primary={text.nombre} />
            </StyledMenuItem>
          ))
        }
      </StyledMenu>
    </div>
  );
}

/*

*/
