import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
//import Image from 'material-ui-image';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import Avatar from '@material-ui/core/Avatar';
import WebApi from '../../../components/helpers/WebApi';
import Spinner from '../../../components/common/Spinner';
import SocialShare from '../../../components/common/SocialShare';
import MediaControlCard from '../../../components/common/MediaControlCard';
import {colorPrimary,colorTerciario} from '../../../components/helpers/Constantes';
import Blog from '../Blog';
import Temario from './Temario';


import { connect } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  content: {
    maxWidth: 900,
    margin: `${theme.spacing(1)}px auto`,
    paddingHorizontal: theme.spacing(2),
  },
  bannerCurso:{
    marginTop: '-20px',
  },
  titulo:{
    width:'100%',
    fontSize: 40,
    fontWeight: 'bold',
  },
  descripcion:{
    paddingTop: 20,
    width:'100%',
    fontSize: 20,
  },
  columns: {
    padding: '20px !important',
  },
  cursoImagen:{
    backgroundRepeat:'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: 250,
  },
  social:{
    marginTop:30,
    justifyContent:'center',
    textAlign: 'center',
  },
  socialTitle: {
    height: 160,
    paddingTop:20,
    paddingLeft:25,
    background:'#fff',
    paddingBottom: 20,
  },
  purple: {
    color: '#fff',
    backgroundColor: colorTerciario,
    marginRight: 10,
  },
  button: {
    margin: theme.spacing(1),
    width: '100%',
    backgroundColor: colorPrimary,
    height: '45px !important',
    fontWeight: 'bold'
  },
}));


//const posts = [post1, post2, post3];
const posts = [];

function Clase(props) {
  const classes = useStyles();
  const match = props.match;
  const alias= match.params.alias;

  const [curso, setCurso] = React.useState([]);
  const [inscripcion, setInscripcion] = React.useState(false);
  const [show_loader, setShow_loader] = React.useState(false);



  const getCurso = () => {
    setShow_loader(true);
    new WebApi().getCurso(alias).then(result => {
      processGetCurso(result);
    })
  }

  const processGetCurso = (result) => {
    setShow_loader(false);
    if (result.no_result) {
        return
    }
    let { data } = result;
    if(data.result){
      setCurso(data.data);
      setShow_loader(false);
    }
  }

  const getValidate = () => {
    setShow_loader(true);
    new WebApi().getContratoValidar(props.user.id).then(result => {
      processGetCurso(result);
    })
  }

  const processGetValidate = (result) => {
    setShow_loader(false);
    if (result.no_result) {
        return
    }
    let { data } = result;

    if(data.result){
      setInscripcion(false);
    }
    else {
      setInscripcion(true);
    }
  }

   useEffect(() => {
      getCurso();

  },[0]);
  const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  //alert(alias);
  return (
      <Blog content=
          {
            <React.Fragment>
              <Grid container spacing={1} direction="row" className={classes.bannerCurso}>
                <Grid  item
                  sm={12}
                  xs={12}
                  className={classes.cursoImagen}
                  style={{
                    backgroundImage: `url(${curso? new WebApi()._IMAGES_URL + curso.imagen: '' })` ,
                }}>
                </Grid>
                <div className='banner_curso' ></div>
              </Grid>
              <Spinner visible={show_loader}/>
              <Grid container spacing={3} wrap="nowrap" className={classes.content} >
                <Grid  item sm={inscripcion? 8: 12} className={classes.columns}>
                  {curso.nombre ?
                      <React.Fragment>
                      <Typography component="h1" variant='h1' className={classes.titulo}>
                        {curso.nombre && new WebApi().descodificarEntidad(curso.nombre)}
                      </Typography>
                      <Typography variant="subtitle1" paragraph className={classes.descripcion}>
                        {curso.descripcion && renderHTML(curso.descripcion )}
                      </Typography>

                      <Typography variant="subtitle1" paragraph style={{width:'100%', display: 'flex', marginTop:20}}>
                          <Avatar alt={curso.nombre_autor } src="/broken-image.jpg" className={classes.purple} />
                          Autor: {curso.nombre_autor }
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        endIcon={<SendIcon/>}
                        onClick={()=>getValidate()}
                      >
                        Empieza el Curso
                      </Button>
                      <Temario cursoId={curso.id}/>
                      <SocialShare link={new WebApi()._URL_APP +'curso/'+alias} classContainer={classes.social} title='Comparte con tus amigos'/>

                    </React.Fragment>
                    : null
                  }
                </Grid>
              </Grid>
            </React.Fragment>
          }
      />
  );
}



const actions = {

};

const mapStateToProps = function(state) {
  return {
      user: state.user_data.user
  }
}

export default connect(mapStateToProps, actions)(Clase);
