import React,{Component} from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import ImageUploader from "react-images-upload";
import WebApi from '../../../components/helpers/WebApi';
import {CustomInput} from '../../../components/common/Parts';
import Spinner from '../../../components/common/Spinner';

// CommonJS
//const Swal = require('sweetalert2');

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  footer:{
    textAlign:'center',
    marginBottom:20,
    marginTop:20,
  },
 });


class CursoForm extends Component {

  state={
    id:"",
    video: '',
    imagen: '',
    mensaje: '',
    show_loader:false,
    pictures:'',
  }


  ITEM=this.props.item || false;

  componentDidMount(){
    this.getCurso();
  }

  onDrop = (picture) =>{
     this.setState({
       pictures: this.state.pictures,
     });
     //console.log('picture: ',picture);
     new WebApi().uploadImageCurso(this.ITEM, picture).then(result => {
           this.processUploadImage(result);
     });
   }

   processUploadImage = (result) =>{
     if (result.no_result) {
         return
     }
     this.setState({show_loader:false});
     let { data } = result;
     if (data.result) {
       this.setState({
         mensaje: data.message,
       });
     } else {
         this.setMessage(data.message);
     }
   }

  render(){
    const { classes}= this.props; //,  user, hideModal
    return(
      <Grid container spacing={3} direction="row" justify="center" alignItems="center"  >
        <form action="#!" onSubmit={this.handleSubmmit}>
          {this.state.mensaje!=="" && <Alert severity="info" style={{marginBottom:20}}>{this.state.mensaje}</Alert>}
            <Spinner visible={this.state.show_loader}/>
            <div className={classes.root}>
              <Grid container spacing={3} direction="row" justify="center" alignItems="center">
                <Grid  item  sm={8} xs={8}>
                  <CustomInput
                    name={"video"}
                    label={"Url del video de presentación del curso"}
                    onChangeText={this.handleChange}
                    value={this.state.video}
                    required
                  />
                </Grid>
                <Grid  item sm={3} xs={3}>
                  <Button variant="contained" color="primary" type="submit"  > Guardar </Button>
                </Grid>
              </Grid>
              <Grid container spacing={3} direction="row" justify="center" alignItems="center">
                <Grid  item sm={12} xs={12}>
                  <ImageUploader
                    withIcon={true}
                    buttonText='Escoja una imagen de presentación'
                    onChange={this.onDrop}
                    imgExtension={['.jpg', '.png']}
                    maxFileSize={2097152}
                    withPreview={true}
                    singleImage={true}
                    defaultImages={this.state.pictures}
                    fileTypeError='La extensión no es soportada'
                    fileSizeError='El archivo es muy pesado'
                    label='Max tamaño de archivo: 2mb. Aceptados: jpg,  png'
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.footer}>
            </div>
        </form>
      </Grid>

    );
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    });
  }

  setMessage=(mensaje) => {
    this.setState({mensaje});
  }

  handleSubmmit = (e) => {
    e.preventDefault();
    let {video} =this.state;
    if(video.trim() < 2 ) {
      this.setMessage("Escriba la url del video");
      return;
    }

    this.setState({show_loader:true});
      new WebApi().updateVideoCurso( this.ITEM, video).then(result => {
          this.processUpdateVideo(result);
    });
  }

  processUpdateVideo = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        mensaje: data.message,
      });
    } else {
        this.setMessage(data.message);
    }
  }

  getCurso = () => {
    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().getCurso(this.ITEM).then(result => {
      setTimeout(() => {
          this.processGetCursoResponse(result);
      }, 300)
    })
  }

  processGetCursoResponse = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        //se redirecciona automaticamente
        data=data.data;
        this.setState({
          id:data.id,
          video: data.video,
          pictures: data.imagen ?  [new WebApi()._IMAGES_URL + data.imagen] : [] ,
        });
    } else {
        this.setMessage(data.message || 'Ocurrió un error al intentar guardar la información');
    }
  }



  resetForm = () => {
    this.setState({
      id: 0,
      video:'',
      imagen:'',
      mensaje:"",
    })
  }




}

const mapStateToProps = (state) => {
    return {
        user: state.user_data.user
    }
}
const actions = {};

export default connect(mapStateToProps, actions)(withStyles(useStyles, { withTheme: true })(CursoForm));
