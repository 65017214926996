import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
//import Image from 'material-ui-image';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import SendIcon from '@material-ui/icons/Send';
import UpdateIcon from '@material-ui/icons/Update';
import ClassIcon from '@material-ui/icons/Class';
import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import Avatar from '@material-ui/core/Avatar';
import WebApi from '../../../components/helpers/WebApi';
import Spinner from '../../../components/common/Spinner';
import SocialShare from '../../../components/common/SocialShare';
import MediaControlCard from '../../../components/common/MediaControlCard';
import {colorPrimary,colorTerciario, colorSecondary, IMPUESTO,URL_APP} from '../../../components/helpers/Constantes';
import Blog from '../Blog';
import Temario from './Temario';
import BotonDePago from '../../Public/Pagos/BotonDePago';


import { connect } from 'react-redux';


const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: 0,
  },
  content: {
    maxWidth: 900,
    margin: `${theme.spacing(1)}px auto`,
    //paddingHorizontal: theme.spacing(2),
  },
  headerInner: {
    marginBottom: '0px !important'
  },
  couse_intro: {
    backgroundColor: colorSecondary,
    paddingLeft: `${theme.spacing(3)}px `,
    paddingRight: `${theme.spacing(3)}px `,
  },
  bannerCurso:{
    padding: '50px 0 0 0 !important',
  },
  bannerInner:{
    backgroundColor: '#fff',
    border: 'border: 1px solid #fff',
  },
  titulo:{
    width:'100%',
    fontSize: 40,
    fontWeight: 'bold',
    color: '#fff',
  },
  descripcion:{
    paddingTop: 20,
    width:'100%',
    fontSize: 20,
    color: '#fff',
  },
  columns: {
    padding: '20px !important',
  },

  cursoImagen:{
    backgroundRepeat:'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    height: 250,
  },
  social:{
    marginTop:30,
    justifyContent:'center',
    textAlign: 'center',
  },
  socialTitle: {
    height: 160,
    paddingTop:20,
    paddingLeft:25,
    background:'#fff',
    paddingBottom: 20,
  },
  purple: {
    color: '#fff',
    backgroundColor: colorTerciario,
    marginRight: 10,
  },
  button: {
    margin: theme.spacing(1),
    width: '100%',
    backgroundColor: colorPrimary,
    height: '45px !important',
    fontWeight: 'bold'
  },
  sidebar:{
    border: '1px solid #e2e7ed',
    marginTop: 10,
  },
}));


//const posts = [post1, post2, post3];
const posts = [];

function Detalle(props) {
  const classes = useStyles();
  const match = props.match;
  const alias= match.params.alias;

  const [curso, setCurso] = React.useState([]);
  const [inscripcion, setInscripcion] = React.useState('P');
  const [tarifa, setTarifa] = React.useState('');
  const [show_loader, setShow_loader] = React.useState(false);

  const getCurso = () => {
    setShow_loader(true);
    new WebApi().getCurso(alias).then(result => {
      processGetCurso(result);
    })
  }

  const processGetCurso = (result) => {
    setShow_loader(false);
    if (result.no_result) {
        return
    }
    let { data } = result;
    if(data.result){
      setCurso(data.data);
      getValidate(data.data.id);
      setShow_loader(false);
    }
  }

  const getValidate = (cursoId) => {
    setShow_loader(true);
    new WebApi().getContratoValidar(props.user.id, cursoId).then(result => {
      processGetValidate(result);
    })
  }

  const processGetValidate = (result) => {
    setShow_loader(false);
    if (result.no_result) {
        return
    }
    let { data } = result;
    if(data.result){
      data.inscrito? setInscripcion(true) : setInscripcion(false);
      setTarifa(data.tarifa);
    }
    else {
      setInscripcion(false);
    }
  }

   useEffect(() => {
      getCurso();

  },[0]);

  //Si el tipo de tarifa es Solidario se aplica descuento del 50%
  const descuento = curso.precio ? Math.round(curso.precio*0.5*100)/100 : 0; //Descuento 50% Solidario
  const prize = curso.precio ? tarifa==='S'? descuento : curso.precio : 0 ;
  const prizeWithTax= prize? Math.round(prize*(1+IMPUESTO/100)*100)/100:0;

  const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  //alert(alias);
  return (
      <Blog content=
          {
            <React.Fragment>
              <Grid container spacing={3} wrap="nowrap" className={classes.couse_intro} >
                <Grid container spacing={3} wrap="nowrap" className={[classes.content, classes.headerInner].join(' ')} >
                  <Grid  item sm={8} className={classes.columns}>
                    {curso.nombre ?
                        <React.Fragment>
                        <Typography component="h1" variant='h1' className={classes.titulo}>
                          {curso.nombre && new WebApi().descodificarEntidad(curso.nombre)}
                        </Typography>
                        <Typography variant="subtitle1" paragraph className={classes.descripcion}>
                          {curso.corta}
                        </Typography>
                        <Typography  paragraph style={{fontSize:15, color: "#fff", marginTop:20}}>
                          <ClassIcon/>  {curso.categoria_nombre }
                        </Typography>
                      </React.Fragment>
                      : null
                    }
                  </Grid>

                  <Grid  item sm={4} className={classes.bannerCurso}>
                      <Grid container spacing={1} direction="row" className={classes.bannerInner}>
                        <Grid  item
                          sm={12}
                          xs={12}
                          className={classes.cursoImagen}
                          style={{
                            backgroundImage: `url(${curso.imagen? new WebApi()._IMAGES_URL + curso.imagen: '' })` ,
                          }}>
                        </Grid>
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {show_loader &&
              <Spinner visible={show_loader}/>
              }
              <Grid container spacing={3} wrap="nowrap" className={classes.content} >
                <Grid  item sm={8} className={classes.columns}>
                  {curso.nombre ?
                      <React.Fragment>
                      <Typography variant="subtitle1" paragraph style={{width:'100%', display: 'flex', marginTop:20}}>
                          <Avatar alt={curso.nombre_autor } src="/broken-image.jpg" className={classes.purple} />
                          Autor: {curso.nombre_autor }
                      </Typography>
                      <Typography variant="h4"  >
                        Resumen
                      </Typography>
                      <Typography variant="subtitle1" paragraph style={{marginTop:20}}>
                        {curso.descripcion && renderHTML(curso.descripcion )}
                      </Typography>

                      <Temario cursoId={curso.id}/>
                      <SocialShare link={URL_APP +'curso/'+alias} classContainer={classes.social} title='Comparte con tus amigos'/>

                    </React.Fragment>
                    : null
                  }
                </Grid>

                <Grid  item sm={4} className={classes.sidebar}>
                  {
                    inscripcion===true ?
                    <Typography  paragraph style={{fontSize:22, textAlign:'center', marginTop:20}}>
                      Ya puedes acceder a este curso
                    </Typography>:
                    <Typography  paragraph style={{fontSize:30, textAlign:'center', marginTop:20}}>
                      USD  $ { prizeWithTax.toFixed(2)  }
                    </Typography>
                  }

                  { tarifa==='PRO' ?
                    <Typography  paragraph style={{fontSize:16, textAlign:'center', color: 'rgb(168, 169, 172)', }}>
                      Membresía PRO - Acceso Ilimitado
                    </Typography> : null
                  }

                  {tarifa==='S' && inscripcion!=='P' ?
                  <React.Fragment>
                    <Typography  paragraph style={{fontSize:20, textAlign:'center',color: 'rgb(168, 169, 172)', textDecoration: 'line-through'}}>
                      USD  $ { (curso.precio* (1+IMPUESTO/100 )).toFixed(2)  }
                    </Typography>
                    <Typography  paragraph style={{fontSize:14, textAlign:'center', color: 'rgb(168, 169, 172)', }}>
                      Descuento por Membresía Solidaria
                    </Typography>
                    </React.Fragment>
                  : null
                  }

                  {
                    inscripcion==='P' ? //pendiente
                      <CircularProgress style={{ margin:'auto', display:'block'}}/>
                    :
                      inscripcion===true ?
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.button}
                          endIcon={<SendIcon/>}
                          onClick={()=>window.open('/clase/'+alias)}
                        >
                          Empieza el Curso
                        </Button>
                      :

                        <BotonDePago
                          alias={alias}
                          productName={curso.nombre}
                          product_Id={curso.id}
                          amount={prize}
                          amountWithTax={prizeWithTax}
                        />
                   }
                  <Typography  paragraph style={{ marginTop:20}}>
                    <UpdateIcon/> Actualizaciones permanentes
                  </Typography>
                  <Typography  paragraph style={{ marginTop:20}}>
                    <HeadsetMicIcon/> Soporte por tutores en cada leccion
                  </Typography>
                </Grid>
              </Grid>
            </React.Fragment>
          }
      />
  );
}



const actions = {

};

const mapStateToProps = function(state) {
  return {
      user: state.user_data.user
  }
}

export default connect(mapStateToProps, actions)(Detalle);
