import React,{Component} from 'react';
import WebApi from '../../../components/helpers/WebApi';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default class Autores extends Component  {

  constructor(props) {
    super(props);
    this.state={
      value:  '',
      inputValue: '',
      datas:[{id:'1',nombre:'Option 1'}, {id: '2' ,nombre:'Option 2'}],
    }
  }

  componentDidMount(){
    this.searchUsers();
  }

  selectAutor  = (value, inputValue) => {
    this.setState({value:value, inputValue:inputValue});
  }

  searchUsers  = (val) => {
    new WebApi().getAutores(this.state.inputValue, 1).then(result => {
      this.processUsers(result);
    })
  }

  processUsers = (result) => {
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      this.setState({ datas: data.usuarios, });
    } else {
      alert(data.message);
    }
  }


  render(){
    let {value,inputValue}=this.state;
    return (
      <div>
        {
        //  <div>{`value: ${value !== null ? `'${value}'` : 'null'}`}</div>
        }
        {
          value!=='' ?
          <React.Fragment>
            <label className={"MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined Mui-focused Mui-focused"} data-shrink="true" htmlFor="autores" id="autores-label">Autor</label>
            <div>{`Autor: '${inputValue}'`}</div>
            <div  style={{fontSize:8}} onClick={()=>this.setState({value:''})}> Cambiar autor</div>
            <br />
          </React.Fragment>
          :
          <Autocomplete
              onChange={(event, newValue) => {
                newValue!==null &&
                this.setState({
                  value: newValue.id,
                  inputValue:newValue.nombre,
                });
                this.props.setAutor(newValue!==null? newValue.id: '');
              }}
             id="autores"
             options={this.state.datas}
             getOptionLabel={(option) => typeof option.nombre !=='undefined'? option.nombre : ''}
             style={{ width: 270,  marginBottom: 20,marginRight: 10, }}
             renderInput={(params) => <TextField {...params} label="Autor"  variant="outlined" onKeyUp={this.searchUsers} />}
           />
        }
       </div>
    );
  }

}

//, function() {
// if(reason==='input'){ this.searchUsers()
