import React from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
// core components
import styles from "../../assets/jss/material-dashboard-react/components/tableStyle.js";

const useStyles = makeStyles(styles);


// USE ESTA FUNCION PARA ORDENAR Y FILTRAR DATOS
const ordenarDatos= (list, columns) =>{
    return list.map (elem => {
      return columns.map(column=> {
        if(typeof elem[column]!== 'undefined'){
          return elem[column]
        }
      });
    });
 }


export default function CustomTable(props) {
  const classes = useStyles();
  let {
      tableHead, // Array cabecera
      tableData, // Array datos
      tableHeaderColor, // Class clase personalizada del encabezado
      edit, // Function callback al hacer click en un elemento
      customRow , // Function return component fila personalizada si hubiera
      filtrar, // Function return array script de filtrado
      columns, // Object columnas y el orden en que se tomaran los datos
      ignoreCells // String comma separated celdas ignoradas
    } = props;

  const data = filtrar ? filtrar(tableData) : ordenarDatos(tableData, columns) ;

  //ignorar encabezados
  const ignorar =(label)=>{
    if(ignoreCells){
      let  ignoradas= ignoreCells.split(',');
      if(ignoradas.includes(label)){
        return true;
      }
      return false;
    }
    return false;
  }

  const custom = (row, label) => {
    if(customRow){
      return customRow(row, label) || false;
    }
    return false;
  }

  return (
    <div className={classes.tableResponsive}>
      <Table className={classes.table}>
        {tableHead !== undefined ? (
          <TableHead className={classes[ tableHeaderColor ?  tableHeaderColor : "primary" + "TableHeader"]}>
            <TableRow className={classes.tableHeadRow}>
              {tableHead.map((prop, key) => {
                return (
                  !ignorar(tableHead[key]) ?
                  <TableCell
                    className={classes.tableCell + " " + classes.tableHeadCell}
                    key={key}
                  >
                    {prop}
                  </TableCell>: null
                );
              })}
            </TableRow>
          </TableHead>
        ) : null}
        <TableBody>
          {data.map((row, index) => {
            return (
              <TableRow key={index} className={classes.tableBodyRow} onClick={()=> edit? edit(row[1]): null}>
                {row.map((value, key) => {
                  let label= tableHead[key];
                  return ( //personalizado
                    !ignorar(label)?
                    <TableCell className={classes.tableCell} key={key}>
                      {// si hay algo que personalizar devuelve el valor  personalizado
                        custom(row, label) || value
                      }
                    </TableCell> : null
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
}

CustomTable.defaultProps = {
  tableHeaderColor: "gray"
};

CustomTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.arrayOf(PropTypes.string),
//  tableData: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
};
