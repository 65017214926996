import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Image from 'material-ui-image';
import FLogin from './FLogin';
import GLogin from './GLogin';
import LoginForm from './LoginForm';
import learning from '../../assets/images/learning.jpg';

const useStyles = makeStyles((theme) => ({
  modal: {
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
   },
   paper: {
       position: 'absolute',
       width: theme.spacing(100),
       backgroundColor: theme.palette.background.paper,
       boxShadow: theme.shadows[5],
       padding: theme.spacing(2, 4, 4),
       borderRadius:10,

   },
   titleContainter: {
     position: 'absolute',
     top: theme.spacing(8),
   },
   title:{
     paddingLeft: 15,
     paddingRight: 15,
     alignItems: "center",
     color: '#fff',
     fontSize: 32,
   },
   subtitle: {
     alignItems: "center",
     color: '#fff',
     paddingLeft: 15,
     paddingRight: 15,
     fontSize: 18,
   },
   image: {
     paddingLeft: 0,
     paddingTop: 5,
     paddingBottom: 0,
   },

}));

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export default function ModalLogin(props){
  const { open, handleClose } = props;
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

    return (
      <Modal
        disablePortal
        onBackdropClick={handleClose}
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"

      >
        <div style={modalStyle} className={classes.paper}>
          <Grid container spacing={5} >
            <Grid item xs={12} md={6} >
              <Image src={learning} className={classes.image}/>
              <Grid className={classes.titleContainter}>
              <h2 id="server-modal-title" className={classes.title}>Únete hoy</h2>
              <p className={classes.subtitle}> Sé parte de la nueva generación de<br/> profesionales digitales.</p>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <p id="server-modal-description">If you disable JavaScript, you will still see me.</p>
              <Grid container >
                <Grid item xs={12} md={6}>
                  <FLogin />
                </Grid>
                <Grid item xs={12} md={6}>
                  <GLogin />
                </Grid>
              </Grid>
              <Divider />
              <LoginForm />
            </Grid>
           </Grid>


        </div>

      </Modal>
  )
}
