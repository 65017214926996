
import axios from 'axios';
import FormData from 'form-data';

//Esta es la librería que se usa para hacer y recibir todas las peticiones post y get al API
export default class WebApi  {

    constructor(){
      this.url();
    }
    //todas las url y direcciones de la API
    SHOW_ALERT= true;
    _BASE_URL= "https://academia.xperting.club/api/";
    _IMAGES_URL="https://academia.xperting.club/api/";

    PAISES= "paises.json";
    PAIS_SET= "pais_set.json";
    PAIS_DELETE="pais_eliminar.json";
    PERMISSIONS= "permissions.json";

    USER_LOGIN = "login.json";
    USER_LOGIN_SOCIAL = "login_social.json";
    USER_RECOVERY = "recovery_password.json"
    USER_SET_PROFILE = "usuario_set.json";
    USER_GET_PROFILE = "usuario_get.json";
    USER_SIGNUP = "register.json";
    AUTORES = "autores.json";
    USERS = "usuarios.json";
    USERS_CLIENTES = "clientes.json";
    USER_GET= "get_user.json";
    USERS_SEARCH= "usuarios_search.json";
    USERS_ELIMINAR="usuario_eliminar.json";
    CURSOS="cursos.json";
    CURSOS_MEJORES="cursos_mejores.json";
    CURSO_GET="curso_get.json";
    CURSO_SET="curso_set.json";
    CURSO_SET_VIDEO='curso_set_video.json';
    CURSO_SET_PICTURE='curso_set_picture.json';
    CURSO_DELETE="curso_delete.json";
    CURSO_VALIDAR="curso_validar.json";

    DESTINATARIOS="certificados_destinatarios.json";
    DESTINATARIO_GET="certificados_destinatario.json";
    DESTINATARIO_SET="certificados_destinatario_set.json";
    CERTIFICADO_VALIDATE="certificado_validate.json";
    CERTIFICADOS_UNICOS="certificados_unicos.json";
    CERTIFICADOS_UNICOS_TP="certificados_unicos_TP.json";

    CATEGORIAS='categorias.json';
    //CLASES DEL CURSO
    CLASES='curso_clase.json';
    CLASE_GET='curso_clase_get.json';
    CLASE_SET='curso_clase_set.json';
    CLASE_DELETE='curso_clase_delete.json';

    NOTICIAS= 'noticias.json';
    NOTICIA_GET= 'noticiajson_get.json';
    NOTICIAS_LATEST='noticias_latest.json';

    CONTRATO_VALIDATE = 'contrato_validate.json';
    CERTIFICADO_GET='certificado_get.json';
    CERTIFICADO_SET='certificado_set.json';
    CERTIFICADO_UPLOAD='certificado_upload.json';

    STATS = 'stats.json';

    url() {
      let api= localStorage.getItem('api');
      switch (api) {
        case 'xperting':
          this._BASE_URL= "https://api.xperting.club/web/";
          this._IMAGES_URL="http://admin.xperting.club/";
          break;
        case 'sinintermediarios':
          this._IMAGES_URL = "https://sinintermediarios.xperting.club/";
          this._BASE_URL = "https://sinintermediarios.xperting.club/api/web/";
          break;
        default:
          this._BASE_URL= "https://academia.xperting.club/api/";
          this._IMAGES_URL="https://academia.xperting.club/api/";
          break;
     }
   }

    async sendGetRequest(_url, _params, external=false) {
        if(!navigator.onLine){
          alert("Al parecer tiene problemas con su conexión a internet");
          let err = [];
          err.error = "Al parecer tiene problemas con su conexión a internet";
          err.no_result = true;
          return err;
        }
        //this.url();
        if(!external){
            _url = this._BASE_URL + _url;
        }

        console.log("API _url", _url);

        try {
            let response = await axios.get(_url, {
                data: _params ? _params : null,
                timeout: 15000
            });

            console.log("API call response", response)
            return response;

        } catch (error) {
            let err = [];
            err.error = error;
            err.no_result = true;
            console.log("catch error on ", _url, " call fail", err)
            setTimeout(() => {
                this.SHOW_ALERT &&
                alert("No se han encontrado resultados");
                console.log("No se han encontrado resultados");
            }, 400)
            return err;
        }
    }

    async sendPostRequest(_url, _params) {
        if(!navigator.onLine){
          alert("Al parecer tiene problemas con su conexión a internet");
          let err = [];
          err.error = "Al parecer tiene problemas con su conexión a internet";
          err.no_result = true;
          return err;
        }
        _url = this._BASE_URL + _url;
        console.log("API _url", _url);

        if (!_params) {
            _params = {}
        }
        try {
            let response = await axios({
                method: 'post',
                url: _url,
                data: _params,
                timeout: 15000
            });
            console.log("API call response", response);
            return response;

        } catch (error) {
            let err = [];
            err.error = error;
            err.no_result = true;
            console.log("catch error on ", _url, " call fail", err)
            setTimeout(() => {
                this.SHOW_ALERT &&
                alert("No hay resultados para mostrar");
            }, 400)
            return err;
        }
    }

    async sendPostFileRequest(_url, _params,file) {
      if(!navigator.onLine){
        alert("Al parecer tiene problemas con su conexión a internet");
        let err = [];
        err.error = "Al parecer tiene problemas con su conexión a internet";
        err.no_result = true;
        return err;
      }
      _url = this._BASE_URL + _url;
      console.log("API FILE _url", _url );
      const form_data = new FormData();
      //var imageType = /image.*/;
      //if (!file.type.match(imageType)) return;
      form_data.append('file', file);
      /*if(typeof file.name!=="undefined"){
        form_data.append('file', file, file.name);
      }*/

      for (const prop in _params){
         form_data.append(`${prop}`, `${_params[prop]}`);
      }

      for (var key of form_data.entries()) {
       console.log(key[0] + ', ' + key[1]);
     }
      try {
         let response = await axios({
               method: 'post',
               url: _url,
               data: form_data,
               timeout: 15000,
               headers: {
               'Content-Type': 'multipart/form-data',
               }
           });

          console.log("API call response", response);
          return response;
      } catch (error) {
          let err = [];
          err.error = error;
          err.no_result = true;
          console.log("catch error on ", _url, " call fail", err);
          setTimeout(() => {
              this.SHOW_ALERT && alert('No se han encontrado resultados');
          }, 400)
          return err;
      }
    }

    editProfile(user_id,
      nombre, apellido, email, direccion, cedula, telefono,
            pais, permiso, contrasenia){
        let url= this.USER_SET_PROFILE +
            "?user_id=" + user_id +
            "&nombre=" + nombre +
            "&apellido=" + apellido +
            "&email=" + email +
            "&direccion=" + direccion +
            "&cedula=" + cedula +
            "&telefono=" + telefono +
            "&pais=" + pais +
            "&permiso="+permiso +
            "&contrasenia="+contrasenia
            ;
            return this.sendGetRequest(url)
    }

    editDestinatario(user_id,nombre,cedula){
        let url= this.DESTINATARIO_SET +
            "?user_id=" + user_id +
            "&nombre=" + nombre  +
            "&cedula=" + cedula
            ;
            return this.sendGetRequest(url)
    }

    getProfile(id){
        let url = this.USER_GET_PROFILE + "?id="+id
        return this.sendGetRequest(url)
    }

    socialLogin(id,name, email, picture, accessToken, network) {
        let url = this.USER_LOGIN_SOCIAL;
        let body=`netid=${id}&name=${name}&email=${email}&picture=${picture}&accessToken=${accessToken}&network=${network}`;
        return this.sendPostRequest(url,body)
    }

    userLogin(_email, _password) {
        //?email=mail@ejemplo.com&pass=xxxxxx
        let url = this.USER_LOGIN + "?email=" + _email + "&pass=" + _password;
        return this.sendGetRequest(url)
    }

    getClientes(search, pag) {
        let url = this.USERS_CLIENTES + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }

    getUsers(search, pag) {
        let url = this.USERS + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }

    getAutores(search, pag) {
        let url = this.AUTORES + `?search=${search}`;
        return this.sendGetRequest(url);
    }

    //BUSCAR CLIENTES PARA  AUTOCOMPLETE
    getSearch(search, pag) {
        let url = this.USERS_SEARCH + `?search=${search}&pag=${pag}&autocomplete=true`;
        return this.sendGetRequest(url);
    }


    getUser(id) {
        let url = this.USER_GET + `?idUser=${id}`;
        return this.sendGetRequest(url);
    }

    deleteUser(id){
        let url = this.USERS_ELIMINAR + `?user_id=${id}`;
        return this.sendGetRequest(url);
    }

    getPaises(search, page){
      let url = this.PAISES + `?status=A&search=${search}&pag=${page}`;
      return this.sendGetRequest(url);
    }

    setPais(id,nombre){
      let url = this.PAIS_SET ;
      let body= `id=${id}&nombre=${nombre}`;
      return this.sendPostRequest(url, body);
    }

    deletePais(id){
        let url = this.PAIS_DELETE + `?id=${id}`;
        return this.sendGetRequest(url);
    }

    getPermissions(){
      let url = this.PERMISSIONS + `?status=A`;
      return this.sendGetRequest(url);
    }

    getAllPaises(){
      let url = this.PAISES + `?status=A&getAll=true`;
      return this.sendGetRequest(url);
    }

    getNoticiasLatest(page, search=''){
      let url = this.NOTICIAS_LATEST ;

      return this.sendGetRequest(url);
    }

    getExternalCourse(page, search=''){
      //let url = this.NOTICIAS + `?pag=${page}`;
      let url= "https://www.xperting.club/wp-json/learnpress/v1/courses/?per_page=3&page="+page;
      if(search!=''){
        url += `&search=${search}`;
      }
      let params=[];
      return this.sendGetRequest(url,params,true);
    }

    getNoticias(page, search=''){
      //let url = this.NOTICIAS + `?pag=${page}`;
      let url= "https://www.xperting.club/wp-json/wp/v2/posts?per_page=4&page="+page;
      if(search!=''){
        url += `&search=${search}`;
      }
      let params=[];
      return this.sendGetRequest(url,params,true);
    }

    getNoticia (id){
      let url = this.NOTICIA_GET + `?id=${id}`;
      return this.sendGetRequest(url);
    }

    getAllCategorias(){
      let url = this.CATEGORIAS + `?status=A&getAll=true`;
      return this.sendGetRequest(url);
    }

    getContratoValidar(usuario, curso){
      let url = this.CONTRATO_VALIDATE + `?usuario=${usuario}&curso=${curso}`;
      return this.sendGetRequest(url);
    }

    getCursoValidar(search,pag){
      let url = this.CURSO_VALIDAR + `?search=${search}&pag=${pag}`;
      return this.sendGetRequest(url);
    }
    //todos los certificados generales emitidos
    getCertificadoValidate(code) {
        let url = this.CERTIFICADO_VALIDATE + `?code=${code}`;
        return this.sendGetRequest(url);
    }

    //ENTIDADES DESTINATARIAS DE CERTIFICADOS
    getDestinatarios(search, pag) {
        let url = this.DESTINATARIOS + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }

    getDestinatario(id) {
        let url = this.DESTINATARIO_GET + `?idUser=${id}`;
        return this.sendGetRequest(url);
    }

    getCertificadosUnicos(search,pag){
      let url = this.CERTIFICADOS_UNICOS + `?search=${search}&pag=${pag}`;
      return this.sendGetRequest(url);
    }

    getCertificadosUnicosTiaPatty(search,pag){
      let url = this.CERTIFICADOS_UNICOS_TP + `?search=${search}&pag=${pag}`;
      return this.sendGetRequest(url);
    }

    getCertificado(id){
      let url = this.CERTIFICADO_GET + `?id=${id}`;
      return this.sendGetRequest(url);
    }

    setCertificado(id, destinatario, proposito, programa ,descripcion, fecha){
      let url= this.CERTIFICADO_SET;
      let body="id=" + id +
            "&destinatario=" + destinatario +
            "&proposito=" + proposito +
            "&programa=" + programa +
            "&descripcion=" + descripcion +
            "&fecha=" + fecha
            ;
      return this.sendPostRequest(url, body);

    }

    getCursosMejores(search, pag) {
        let url = this.CURSOS_MEJORES + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }

    getCurso(id) {
        let url = this.CURSO_GET + `?curso=${id}`;
        return this.sendGetRequest(url);
    }

    getCurso(id) {
        let url = this.CURSO_GET + `?curso=${id}`;
        return this.sendGetRequest(url);
    }

    getCursos(search, pag) {
        let url = this.CURSOS + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }

    getCursosLatest(search, pag) {
        let url = this.CURSOS + `?search=${search}&pag=${pag}`;
        return this.sendGetRequest(url);
    }

    editCurso(id,
      nombre, autor,publicacion,publicacion_hora,alias,precio,categoria,descripcion){
        let url= this.CURSO_SET;
        let body= "id=" + id +
            "&nombre=" + nombre +
            "&autor=" + autor +
            "&publicacion=" + publicacion+ ' '+ publicacion_hora +
            "&alias=" + alias +
            "&precio=" + precio +
            "&categoria=" + categoria +
            "&descripcion=" + descripcion;
        return this.sendPostRequest(url, body);
    }

    updateVideoCurso(id,  video){
        let url= this.CURSO_SET_VIDEO;
        let body= "id=" + id +
            "&video=" + video ;
        return this.sendPostRequest(url, body);
    }

    deleteCurso(id){
        let url = this.CURSO_DELETE + `?curso_id=${id}`;
        return this.sendGetRequest(url);
    }

    getClase(id) {
        let url = this.CLASE_GET + `?clase=${id}`;
        return this.sendGetRequest(url);
    }

    editClase(id,  titulo, video, servidor, descripcion, curso_id){
        let url= this.CLASE_SET;
        let body= "id=" + id +
            "&titulo=" + titulo +
            "&video=" + video +
            "&tipo=" + servidor +
            "&descripcion=" + descripcion +
            "&curso="+ curso_id  ;
        return this.sendPostRequest(url, body);
    }

    deleteCurso(id){
        let url = this.CLASE_DELETE + `?curso_id=${id}`;
        return this.sendGetRequest(url);
    }

    getCursosClases(id) {
        let url = this.CLASES + `?curso=${id}`;
        return this.sendGetRequest(url);
    }

    descodificarEntidad(str) {
        return str.replace(/&#(\d+);/g, function(match, dec) {
          return String.fromCharCode(dec);
        });
    }

    getReportesPrincipal(){
      let url = this.STATS;
      return this.sendGetRequest(url);
    }

    uploadImageCurso(id_curso,file){
      let url = this.CURSO_SET_PICTURE ;
      let params = 'id='+id_curso+'&eliminar=true';
      if(typeof file === 'undefined' || file.length === 0){
        return this.sendPostRequest(url, params);
      }
      let _params = { 'id':id_curso };
      return this.sendPostFileRequest(url,_params, file[0]);
    }

    uploadFile(id_curso,file){
      let url = this.CERTIFICADO_UPLOAD ;
      if(typeof file === 'undefined' || file.length === 0){
        return false;
      }
      let _params = { 'oficio':id_curso };
      return this.sendPostFileRequest(url,_params, file);
    }

}
