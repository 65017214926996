import React,{useEffect} from 'react';
import Respuesta from './Respuesta';
import Main from '../Main';

const posts = [];

function Exito(props) {

  return (
      <Main content=
          {
            <Respuesta/>
          }
      />
  );
}


export default Exito;
