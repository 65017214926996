import React,{Component} from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import WebApi from '../../../components/helpers/WebApi';
import {CustomInput, ComboBox} from '../../../components/common/Parts';
import Spinner from '../../../components/common/Spinner';
import Autores from '../Users/Autores';
// CommonJS
//const Swal = require('sweetalert2');

const useStyles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  footer:{
    textAlign:'center',
    marginBottom:20,
  },
 });


class CursoForm extends Component {
  constructor(props) {
    super(props);
    // crear ref

    this.autores = React.createRef();
  }

  state={
    id:"",
    nombre:"",
    autor:"",
    autor_nombre:'',
    publicacion:"",
    publicacion_hora: "",
    alias:"",
    precio:"",
    categoria:'',
    descripcion:'',
    categorias:[],
    autores: [],
    mensaje:"",
    show_loader:false,
//`id`, `nombre`, `autor`, `publicacion`, `alias`, `precio`, `cat_Id`,  `descripcion`
  }

  ITEM=this.props.item || false;

  componentDidMount(){
    this.getCurso();
    this.handleCategorias();

  }

  getFechaActual = () => {
    let {publicacion}=this.state;
    if(publicacion!==''){
      return publicacion;
    }
    let f = new Date();
    return f.getFullYear() + "-" + (f.getMonth() +1) + "-" + f.getDate() ;
  }

  getHoraActual = () => {
    let { publicacion_hora }=this.state;
    if(publicacion_hora!==''){
      return publicacion_hora;
    }
    let f = new Date();
    return f.getHours() + ":" + f.getMinutes()
  }

  setAutor=(autor)=>{
    this.setState({autor:autor});
  }


  render(){
    //user,hideModal
    const { classes, }= this.props;
    return(
      <Grid container spacing={3} direction="row" justify="center" alignItems="center"  >
        <form action="#!" onSubmit={this.handleSubmmit}>
          {this.state.mensaje!=="" && <Alert severity="info" style={{marginBottom:20}}>{this.state.mensaje}</Alert>}
            <Spinner visible={this.state.show_loader}/>
            <Grid container spacing={3} direction="row" justify="center" alignItems="center">
              <Grid  item sm={6} xs={8}>
                <CustomInput
                  name={"nombre"}
                  label={"Nombre del Curso"}
                  onChangeText={this.handleChange}
                  value={this.state.nombre}
                  required
                  fullWidth={true}
                />
              </Grid>
              <Grid  item sm={3} xs={8}>
                <CustomInput
                  type='date'
                  name={"publicacion"}
                  label={"Fecha de Publicacion"}
                  onChangeText={this.handleChange}
                  value={this.getFechaActual()}
                  fullWidth={true}
                />
              </Grid>
              <Grid  item sm={3} xs={8}>
                <CustomInput
                  type='time'
                  name={"publicacion_hora"}
                  label={"Hora de Publicacion"}
                  onChangeText={this.handleChange}
                  value={this.state.publicacion_hora}
                  fullWidth={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} direction="row" justify="center" alignItems="center">
              <Grid  item sm={4} xs={8}>
                <ComboBox
                items={this.state.categorias}
                name={"categoria"}
                label={"Categorias"}
                onChangeText={this.handleChange}
                value={this.state.categoria}
                required
              />
              </Grid>
              <Grid  item sm={3} xs={8}>
                <CustomInput
                  name={"precio"}
                  label={"Precio"}
                  onChangeText={this.handleChange}
                  value={this.state.precio}
                  required
                  fullWidth={true}
                />
              </Grid>
              <Grid  item sm={5} xs={8}>
                <Autores setAutor={this.setAutor} ref="autores"/>
              </Grid>
            </Grid>
            <Grid container spacing={3} direction="row" justify="center" alignItems="center">
              <Grid  item sm={12} xs={8}>
                <CustomInput
                  name={"descripcion"}
                  label={"Descripción"}
                  onChangeText={this.handleChange}
                  value={this.state.descripcion}
                  required
                  fullWidth={true}
                  multiline={true}
                />
              </Grid>
            </Grid>
            <div className={classes.footer}>
              <Button variant="contained" color="primary" type="submit"  >Guardar Curso</Button>
              { /*
                hideModal &&  <Button variant="contained" onClick={hideModal}>Cancelar</Button> */}

              {!this.ITEM && <Button variant="contained" onClick={this.resetForm}>Limpiar</Button>}
            </div>
        </form>
      </Grid>

    );
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    });
  }

  setMessage=(mensaje) => {
    this.setState({mensaje});
  }

  handleSubmmit = (e) => {
    e.preventDefault();
    let { nombre, autor,publicacion,publicacion_hora, alias,precio,categoria,descripcion} =this.state;
    if(nombre.trim() < 2 ) {
      this.setMessage("Escriba el nombre");
      return;
    }
    if(autor.trim() < 1 ) {
      this.setMessage("Escriba el autor");
      return;
    }
    if(publicacion.trim() ==='' ) {
      this.setMessage("Escoja la fecha de la publicación");
      return;
    }
    if(publicacion_hora.trim()===''){
      this.setMessage("Escoja la hora de la publicación");
      return;
    }
    /*if(alias.trim() < 2 ) {
      alert("Escriba el alias");
      return;
    }*/
    if(precio.trim() < 1 ) {
      this.setMessage("Escoja la precio");
      return;
    }
    if(categoria.trim() < 1 ) {
      this.setMessage("Escoja la categoría");
      return;
    }
    if(descripcion.trim() < 1 ) {
      this.setMessage("Escoja el descripción");
      return;
    }

    this.setState({show_loader:true});
      new WebApi().editCurso( this.ITEM, nombre, autor,publicacion,publicacion_hora,alias,precio,categoria,descripcion).then(result => {
        setTimeout(() => {
            this.processSetUser(result);
            typeof this.props.handleGetUsers !== "undefined" && this.props.handleGetUsers()
            typeof this.props.hideModal !== "undefined" && this.props.hideModal()
        }, 300)
    })
  }

  processSetUser = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
      });

    } else {
        this.setMessage(data.message);
    }
  }

  getCurso = () => {
    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().getCurso(this.ITEM).then(result => {
      setTimeout(() => {
          this.processGetCursoResponse(result);
      }, 300)
    })
  }

  processGetCursoResponse = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        //se redirecciona automaticamente
        data=data.data;
        let fecha=data.publicacion.split(' ');
        this.setState({
          id:data.id,
          nombre: data.nombre,
          alias: data.alias,
          categoria: data.categoria,
          publicacion: fecha[0],
          publicacion_hora: fecha[1],
          descripcion: data.descripcion,
          autor: data.autor,
          precio: data.precio,
          autor_nombre: data.nombre_autor,
        });
        this.refs.autores.selectAutor(data.autor,data.nombre_autor);
    } else {
        this.setMessage(data.message || 'Ocurrió un error al intentar guardar la información');
    }
  }



  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      autor:"",
      autor_nombre: '',
      publicacion:"",
      publicacion_hora: '',
      alias:"",
      precio:"",
      categoria:'',
      descripcion:'',
      categorias:[],
      autores: [],
      mensaje:"",
    })
  }

  handleCategorias = () => {
      //console.log(this.state);
      new WebApi().getAllCategorias().then(result => {
        //setTimeout(() => {
            this.processCategorias(result);
        //}, 300)
      })
  }

  processCategorias = (result) => {
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          this.setState({
            categorias: data.categorias
          });
      } else {
          this.setMessage(data.message);
      }
  }

}

const mapStateToProps = (state) => {
    return {
        user: state.user_data.user
    }
}
const actions = {};

export default connect(mapStateToProps, actions)(withStyles(useStyles, { withTheme: true })(CursoForm));
