import React from 'react';
import {createMuiTheme, ThemeProvider,  makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Header from '../Header';
import Footer from '../Footer';

import { sections} from '../../components/helpers/Constantes';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(1.6),
  },
}));
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Karla',
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Karla',
      },
    },
  },
});


//const posts = [post1, post2, post3];
const posts = [];

export default function Blog(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header title="Geek Learning" sections={sections} />
        <Container maxWidth="lg">

          <main>
            <Grid container spacing={5} className={classes.mainGrid}>
              {props.content}
            </Grid>
          </main>
        </Container>
        <Footer title="Footer" description="¡Nos encanta aportar a tu crecimiento!" />
      </ThemeProvider>
    </React.Fragment>
  );
}
