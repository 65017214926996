import React,{Component} from 'react';

import UserForm from './UserForm';

class User extends Component {


  render (){
    return (
      <UserForm/>

    )
  }


}



export default User;

/*
<!--<a href="#!" className="forgot-password-link">Forgot password?</a> -->
<!--<p className="login-wrapper-footer-text">Don't have an account? <a href="#!" className="text-reset">Register here</a></p>-->
*/
