
import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Spinner from './Spinner';
import WebApi from '../helpers/WebApi';
import Moment from 'react-moment';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import 'moment/locale/es';
import 'moment-timezone';

const useStyles = theme => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  link: {
    margin: theme.spacing(1, 1.5),
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 'bold',
    color: '#1d2671',
  },
  cardValidate: {
     minWidth: 400,
     marginBottom: 5,
     cursor:'pointer',
  },
  name: {
    fontSize: 14,
  },
  title:{
    fontSize:18,
  },
  button:{
    marginBottom: 16,
    marginRight: 16,
    width:48,
    height:48,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#E8EAF0',
    borderRadius: 2,
    backgroundColor: 'transparent',
    padding: 10,

  },
  buttonIcon:{
    color:'#1d2671',
  },
  buttonFile:{
    width:'100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});


class Validar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      certificados: [],
      search: '',
      show_loader: false,
      mensaje:'',
      search_active: '',
      showLastButton:false
    };
    this.PAGE=1;
    }

     openUrl=(link)=>{
      window.open('/','_self');
     }

    render () {
      let {certificados, search, pag, mensaje, search_active, showLastButton} = this.state;
      let {classes, open, close} = this.props;
      return (
        <Dialog open={open} close={close} aria-labelledby="form-dialog-title">
          <DialogTitle id="form-dialog-title">Validar Cetificados</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Encuentra Personas o empresas Certificadas en Xperting.
            </DialogContentText>
            {mensaje!=='' && this.formatMensaje()}

            {!search_active ?
              <React.Fragment>
                <Spinner visible={this.state.show_loader}/>
                <TextField
                  autoFocus
                  margin="dense"
                  id="name"
                  label="Buscar por nombre, DNI, No. certificado, temática o pais "
                  type="text"
                  fullWidth
                  onChange={event => this.setState({search:event.target.value})}
                />

              </React.Fragment>: /* Si encontro datos de cursos realizados previamente*/
              <React.Fragment>
                 {certificados.map((post,i) => (
                   this.rowResult(post)
                 ))}
               </React.Fragment>
            }
            {
              //cuando haya resultados o cuando esté en la segunda pagina pero mostrando mensajes
              search_active &&
              <div className={classes.buttonFile}>
               { this.PAGE>1 && <div className={classes.button} onClick={()=>this.changePage('back')}><ArrowBackIcon className={classes.buttonIcon}/></div> }
               { showLastButton && <div className={classes.button} onClick={()=>this.changePage('next')}><ArrowForwardIcon className={classes.buttonIcon}/></div> }
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancelar
            </Button>
            {!search_active ?
            <Button onClick={()=>this.getValidar()} color="primary">
              Buscar
            </Button>:
            <Button onClick={()=>this.Reiniciar()} color="primary">
              Reiniciar Búsqueda
            </Button>
            }
          </DialogActions>
        </Dialog>
      )
    }

    Reiniciar = () => {
      this.PAGE=1;
      this.setState({
        search:'',
        certificados:[],
        mensaje: '',
        search_active:false
      });
    }

    rowResult = (post) =>{
      //.nombre,post.nombre_curso,post.dni, post.fecha
      const { classes } = this.props;
      return (
        <Card className={classes.cardValidate} variant="outlined" onClick={()=>window.open('/validar/'+post.code,'_blank')} >
        <CardContent>
          <Typography className={classes.name} color="textSecondary" gutterBottom>
            {post.nombre_usuario} - {post.dni}
          </Typography>
          <Typography variant="h5" component="h2" className={classes.title}>
            {post.nombre_curso}
          </Typography>
          <Typography color="textSecondary">
            Cod. {post.code} - <Moment format="DD-MMM-YYYY" locale={'es'} tz={'America/Guayaquil'}>{post.fecha}</Moment>
          </Typography>

        </CardContent>
      </Card>
      )
    }

    formatMensaje = () => {
      return(
        <Typography variant="h5" component="h2">
          {this.state.mensaje}
        </Typography>
      )
    }

    changePage= (action) => {
      switch (action) {
        case 'next':
          this.PAGE++;
          this.getValidar();
          break;
        default:
          this.PAGE>1 && this.PAGE-- ;
          this.getValidar();
      }
    }

     getValidar = () => {
      let {search} = this.state;
      if(search==='') {
        return;
      }
      this.setState({show_loader:true});
      new WebApi().getCursoValidar(search, this.PAGE).then(result => {
        this.processGetValidar(result);
      })
    }

     processGetValidar = (result) => {
      this.setState({show_loader:false});
      if (result.no_result) {
          return
      }
      let { data } = result;
      if(data.result){
        this.setState({certificados:data.data, mensaje: '', search_active:true, showLastButton:true});
      } else {
        this.setState({mensaje: data.message, certificados:[], showLastButton:false, })
      }
    }
}

export default withStyles(useStyles, { withTheme: true })(Validar);
