import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import CursoCinta from './CursoCinta';
import FeedCursos from './FeedCursos';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0),
  },
  divider :{
    marginTop:40,
    marginBottom:20,
  },

}));

function BlogContent(props) {
  const classes = useStyles();
  //const {  title } = props; //posts
  return (

    <Grid item xs={12} md={12}>
      <Typography variant="h5" gutterBottom >
        {"Hola "+ props.user.nombre + " ¡Te damos la Bienvenida!"}
      </Typography>
      <Divider />
      <CursoCinta />
      <Divider className={classes.divider} />

      <FeedCursos/>
    </Grid>
  );
}

/*BlogContent.propTypes = {
  posts: PropTypes.array,
  title: PropTypes.string,
};*/


const actions = {

};

const mapStateToProps = function(state) {
  return {
      user: state.user_data.user
  }
}

export default connect(mapStateToProps, actions)(BlogContent);
