import React,{useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Spinner from '../../../components/common/Spinner';
import Moment from 'react-moment';
import 'moment/locale/es';
import 'moment-timezone';
import WebApi from '../../../components/helpers/WebApi';


const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  media: {
    height: 140,
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
});

export default function FeaturedPost(props) {
  const [featuredCourses, setFeatured] = React.useState([]);
  const [page, setPage] = React.useState(1);const [show_loader, setShow_loader]= React.useState(false);

  const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  const classes = useStyles();
  const { post } = props;
  //console.log('post: ',post);
  const openNotice=(url)=>{
    window.open(url,'_blank')
  }

  const getNoticias = () => {
    setShow_loader(true);
    new WebApi().getExternalCourse(page).then(result => {
      processGetNoticias(result);
    })
  }

  const processGetNoticias = (result) => {
    setShow_loader(false);
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.length>0) {
      setFeatured([...featuredCourses, ...data]);
      console.log(featuredCourses);
      /*  if(state.featuredCourses.length===0){
        } else {
            setFeatured(prev =>[...prev, data]);
        }*/
    }
  }

  useEffect(() => {
     getNoticias();
 },[0]);
  const Course=(props)=>{
    const { post } = props;
    return(
      <Grid item xs={12} md={4}>
      <Card className={classes.root}>
        <CardActionArea>
          <CardMedia
            component="img"
            alt={post.name && new WebApi().descodificarEntidad(post.name)}
            height="140"
            image={post.image}
            title={post.name && new WebApi().descodificarEntidad(post.name)}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {post.name && new WebApi().descodificarEntidad(post.name)}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {post.name && new WebApi().descodificarEntidad(post.name)}
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <Moment format="DD - MMMM - YYYY" locale={'es'} tz={'America/Guayaquil'}>{post.date_created}</Moment>
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions>

          <Button size="small" color="primary" onClick={(e)=>{e.preventDefault(); openNotice(post.permalink)}}>
            Conoce más
          </Button>
        </CardActions>
      </Card>

      </Grid>
    )
  }

  return (
    <React.Fragment>
    <Typography variant="h3" component="h3" align='center' style={{fontSize:32, fontWeight: 'bold',marginTop:40}}>
      Nuestros Cursos
    </Typography>
    <Divider light style={{marginBottom:30}}/>
    <Grid container spacing={4} style={{paddingLeft:10, paddingRight:10}}>
      {typeof featuredCourses !=='undefined'  && featuredCourses.map((post,i) => (
        <Course key={i} post={post} />
      ))}
    </Grid>
    <Spinner visible={show_loader}/>

    </React.Fragment>
  );
}
