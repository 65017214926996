import React , {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
//import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import Store from "@material-ui/icons/Store";

import DateRange from "@material-ui/icons/DateRange";
import Update from "@material-ui/icons/Update";
//import ArrowUpward from "@material-ui/icons/ArrowUpward";
//import AccessTime from "@material-ui/icons/AccessTime";
import Accessibility from "@material-ui/icons/Accessibility";
//import BugReport from "@material-ui/icons/BugReport";
//import Code from "@material-ui/icons/Code";
//import Cloud from "@material-ui/icons/Cloud";
// core components
import GridItem from "../../components/common/Grid/GridItem.js";
import GridContainer from "../../components/common/Grid/GridContainer.js";

//import Danger from "../../components/common/Typography/Danger.js";
import Card from "../../components/common/Card/Card.js";
import CardHeader from "../../components/common/Card/CardHeader.js";
import CardIcon from "../../components/common/Card/CardIcon.js";
//import CardBody from "../../components/common/Card/CardBody.js";
import CardFooter from "../../components/common/Card/CardFooter.js";

import { withStyles,makeStyles } from '@material-ui/core/styles';
import InfoIcon from '@material-ui/icons/Info';
import ClassIcon from '@material-ui/icons/Class';
//import Element from './Element';
import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle.js";

import WebApi from '../../components/helpers/WebApi';
import Table from '../../components/common/Table';


const useStyles = makeStyles(styles);

function Content(props) {
  const classes = useStyles();
  //const { classes } = props;
  const [state, setState] = useState({hoy:[],totalHoy:0, ayer:[], totalAyer:0, semana:[], totalSemana:0});

  const filtrarDatos= (list, columns) =>{
     return list.map (elem => {
       return columns.map(column=> {
         if(typeof elem[column]!== 'undefined'){
           return elem[column]
         }
       });
 });
}
  const processGetClasesResponse = (result) => {
    //this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.hoy) {
        //se redirecciona automaticamente
        //data=data.data;
        setState({
          hoy: filtrarDatos(data.hoy.personas, ['registrado','telefono']),
          totalHoy: data.hoy.total,
          ayer: filtrarDatos(data.ayer.personas, ['registrado','telefono']),
          totalAyer: data.ayer.total,
          semana: filtrarDatos(data.semana.personas, ['registrado','telefono']),
          totalSemana: data.semana.total,
        });
    } else {
        //this.setMessage(data.message || 'Ocurrió un error al intentar guardar la información');
    }
  }

  const getClases = () => {
    //this.setState({show_loader:true});
    new WebApi().getReportesPrincipal().then(result => {
          processGetClasesResponse(result);
    })
  }

  useEffect(() => {
    getClases();
  }, []);

  return (
    <Paper className={classes.paper}>

      <div className={classes.contentWrapper}>
        <GridContainer>
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <ClassIcon/>
                </CardIcon>
                <p className={classes.cardCategory}>Registrados hoy</p>
                <h3 className={classes.cardTitle}>
                   <small>Total</small> {state.totalHoy}
                </h3>
              </CardHeader>
              <CardFooter stats>
                {state.hoy.length > 0 ?
                  <Table
                    tableHeaderColor="primary"
                    tableHead={['Nuevo usuario','Teléfono']}
                    tableData={state.hoy}
                  />
                  :
                  <div className={classes.stats}>
                    <DateRange />
                    No tiene registrados hoy
                  </div>
                }
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Store />
                </CardIcon>
                <p className={classes.cardCategory}>Registrados ayer</p>
                <h3 className={classes.cardTitle}><small>Total</small> {state.totalAyer}</h3>
              </CardHeader>
              <CardFooter stats>
              {state.ayer.length > 0 ?
                <Table
                  tableHeaderColor="primary"
                  tableHead={['Nuevo usuario','Teléfono']}
                  tableData={state.ayer}
                />
                :
                <div className={classes.stats}>
                  <DateRange />
                  No tuvo registrados ayer
                </div>
              }
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <InfoIcon/>
                </CardIcon>
                <p className={classes.cardCategory}>Esta semana</p>
                <h3 className={classes.cardTitle}><small>Total</small> {state.totalSemana}</h3>
              </CardHeader>
              <CardFooter stats>
              {state.semana.length > 0 ?
                <Table
                  tableHeaderColor="primary"
                  tableHead={['Nuevo usuario','Teléfono']}
                  tableData={state.semana}
                />
                :
                <div className={classes.stats}>
                  <DateRange />
                  No tiene registrados esta semana
                </div>
              }
              </CardFooter>
            </Card>
          </GridItem>

        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="warning" stats icon>
                <CardIcon color="warning">
                  <ClassIcon/>
                </CardIcon>
                <p className={classes.cardCategory}>Used Space</p>
                <h3 className={classes.cardTitle}>
                  49/50 <small>GB</small>
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>

                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    Get more space
                  </a>
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="success" stats icon>
                <CardIcon color="success">
                  <Store />
                </CardIcon>
                <p className={classes.cardCategory}>Revenue</p>
                <h3 className={classes.cardTitle}>$34,245</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  Last 24 Hours
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <InfoIcon/>
                </CardIcon>
                <p className={classes.cardCategory}>Fixed Issues</p>
                <h3 className={classes.cardTitle}>75</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>

                  Tracked from Github
                </div>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={3}>
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Accessibility />
                </CardIcon>
                <p className={classes.cardCategory}>Followers</p>
                <h3 className={classes.cardTitle}>+245</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Update />
                  Just Updated
                </div>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </Paper>
  );
}




Content.propTypes = {
  classes: PropTypes.object.isRequired,
};




export default withStyles(styles)(Content);
