import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import QRCode from 'qrcode.react';
import { TextField } from '@material-ui/core';
import TabsTools from './TabsTools';


const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  qrContainer: {
   maxWidth: 700,
   margin: `${theme.spacing(1)}px auto`,
   padding: theme.spacing(2),
  },
  textfield: {
    width: '100%',
    marginBottom: 20,
    textAlign: 'center',
  }
});

class Tools extends Component {

  state ={
      mensaje:'',
      url:'',
  }

  componentDidMount(){

  }


  render (){
    const { classes } = this.props;
    return (
      <React.Fragment>
      {this.state.mensaje!=="" && <Alert severity="info" style={{marginBottom:20}}>{this.state.mensaje}</Alert>}

        <Paper className={classes.paper}>
          <TabsTools
            qrcode={this.qrComponent()}
          />
        </Paper>

      </React.Fragment>
    )
  }

  qrComponent = () =>{
    const { classes } = this.props;
    let {url} = this.state;
    return (
      <Grid container spacing={3} wrap="nowrap"  className={classes.qrContainer}>
        <Grid  item sm  >
          <TextField
            id="url"
            label="Escriba la url para generar"
            onChange={(event)=>this.setState({url:event.target.value}) }
            className={classes.textfield}
            />
            {url!=='' &&
            <div className={classes.textfield}>
              <QRCode value={url}  size={300}/>
            </div>
            }
        </Grid>
      </Grid>
    )
  }

}


export default withStyles(styles)(Tools);
