import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import ModalLogin from './Login/ModalLogin';
import { withStyles } from "@material-ui/core/styles";
import ProfilePopOver from './Login/ProfilePopOver';
import Validar from '../components/common/Validar';
import Categorias from  '../components/common/Categorias';

const useStyles = theme => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
    display: 'flex',
  },
  toolbarLogo: {
    marginRight: 25,
    cursor: 'pointer',
  },
  toolbarContent: {
    flex:1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
    textTransform: 'none',
    fontSize: 15,
    fontWeight: 'bold',
    color: '#1d2671',
    cursor: 'pointer',
  },
  cardValidate: {
     minWidth: 400,
     marginBottom: 5,
  },
  title: {
    fontSize: 14,
  },
});


 class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openModal: false,
      openValidar: false,
    };
    this.PAGE=1;
  }

  handleOpen=()=>{
    this.setState({openModal:true})
  }
  handleClose=()=>{
   this.setState({openModal:false})
  }
   openUrl=(link)=>{
    window.open('/'+link,'_self');
   }
   OpenValidar = () => {
     this.setState({openValidar:true})
   };
   CloseValidar = () => {
     this.setState({openValidar:false})
   };

  render (){
    //const classes = useStyles();
    const { classes } = this.props;
    const { sections, title } = this.props;

    return (
      <React.Fragment>
        <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <div className={classes.toolbarTitle}>
              <Typography variant="h6" color="inherit" noWrap  className={classes.toolbarLogo + ' logo-text'} onClick={()=>window.open('/', '_self')}>
                {title}
              </Typography>
              <Categorias className={classes.toolbarContent}/>
            </div>
            <nav>
              {sections.map((section) => (
                <Link
                  variant="button"
                  color="textPrimary"
                  key={section.title}
                  className={classes.link}
                  onClick={section.title.indexOf('Validar') >-1 ? this.OpenValidar : ()=>this.openUrl(section.url) }
                >
                  {section.title}
                </Link>
              ))}
            </nav>
            {
              /*
              <IconButton>
                <SearchIcon />
              </IconButton>
              */
            }
            {
              this.props.user ?
              <ProfilePopOver title= {this.props.user.nombre}/>
              :
              <Button variant="outlined" size="small" onClick={this.handleOpen}>
                Entrar
              </Button>
            }
          </Toolbar>
        </AppBar>
        <ModalLogin open={this.state.openModal} handleClose={this.handleClose}/>
        <Validar open={this.state.openValidar} close={this.CloseValidar}/>
      </React.Fragment>
    );
  }



}

const mapStateToProps = (state) => {
    return {
        user: state.user_data.user
    }
}

const actions = {

}

export default connect(mapStateToProps, actions)(withStyles(useStyles, { withTheme: true })(Header));

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};
