import React from 'react';
import { GoogleLogin } from 'react-google-login';
import WebApi from '../../components/helpers/WebApi';
import MyStorage from '../../components/helpers/MyStorage';
import Alert from '../../components/common/Alert';
import { setUserData } from '../../components/redux/actions/ActionsUserData';
import { connect } from 'react-redux';

class GLogin extends React.Component{
  constructor (props, context) {
    super(props, context);
    this.alerta = React.createRef();
    this.responseGoogle = this.responseGoogle.bind(this);
    this.processLoginResponse = this.processLoginResponse.bind(this);

  }
  state={
    mensaje:'',
  }

  responseGoogle =(googleUser) =>{
    var id_token = googleUser.getAuthResponse().id_token;
    var googleId = googleUser.getId();
    //
    //console.log({accessToken: id_token});
    //anything else you want to do(save to localStorage)...
    var profile = googleUser.getBasicProfile();
    console.log(profile);
    let id=  profile.getId(); // Do not send to your backend! Use an ID token instead.
    let name= profile.getName();
    let picture= profile.getImageUrl();
    let email= profile.getEmail(); // This is null
    if(typeof id !=='undefined'){
      new WebApi().socialLogin(id,name, email, picture, id_token,'google').then(result => {
        this.processLoginResponse(result);
      })
    }
  }

  processLoginResponse = (result) => {
      let { data } = result;
      if (data.result) {
          //se redirecciona automaticamente
          let user = data.data;
          this.props.setUserData(user);
          user = JSON.stringify(data.data);
          new MyStorage().setUserInfo(user);
      } else {
          alert(data.message);
          //this.setState({mensaje: data.mensaje}, ()=> this.alerta.openAlert())
      }
  }


  render () {
    return (
      <div>
        <GoogleLogin clientId="429996939924-gi79m1kmav73r2hjljtv59ns09edkqof.apps.googleusercontent.com"
                     className="googlelogin"
                     prompt="select_account"
                     onSuccess={this.responseGoogle}
                     buttonText="Continúa con Google"
                     cookiePolicy={'single_host_origin'}
         />

         <Alert ref={this.alerta}
               message={this.state.mensaje}
               title={'Aviso'}
               CancelButtonTitle='Entendido'
         />
      </div>
    );
  }

}

const actions = {
    setUserData
}
//export default GLogin;
export default connect(null, actions)(GLogin);
