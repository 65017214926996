import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import 'moment/locale/es';
import 'moment-timezone';

import WebApi from '../../../components/helpers/WebApi';
import Spinner from '../../../components/common/Spinner';
import success from '../../../assets/images/tenor.gif';
import error from '../../../assets/images/error.gif';

import {colorPrimary,colorTerciario} from '../../../components/helpers/Constantes';
import Main from '../Main';

const padd= window.innerWidth > 1200 ? "20% !important": '20px !important';
const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  columns: {
    paddingLeft: padd,
    paddingRight: padd,
  },
  imagen:{
    margin: 'auto',
    display:'block',
    maxWidth: 400,
  },
  content:{
    paddingBottom:40,
  }
}));


//const posts = [post1, post2, post3];
const posts = [];

function Validar(props) {
  const classes = useStyles();
  const match = props.match;
  const code= match.params.code;

  const [certificado, setCertificado] = React.useState([]);
  const [resultado, setResultado] = React.useState('');
  const [show_loader, setShow_loader] = React.useState(false);

  const getCertificado = () => {
    setShow_loader(true);
    new WebApi().getCertificadoValidate(code).then(result => {
      processGetCurso(result);
    })
  }

  const processGetCurso = (result) => {
    setShow_loader(false);
    setResultado(''); //BORRAR DATOS
    if (result.no_result) {
        return
    }
    let { data } = result;
    setResultado(data.result); //marcar si fue o no fue emitido
    if(data.result){
      setCertificado(data.data);
      setShow_loader(false);
    }
  }

   useEffect(() => {
      getCertificado();
  },[0]);
  const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  //alert(alias);
  return (
      <Main content=
          {
            <React.Fragment>

              <Spinner visible={show_loader}/>
              <Grid container spacing={3} wrap="nowrap" className={classes.content} >
                <Grid  item sm  className={classes.columns}>
                {resultado===true &&
                  <React.Fragment>
                  <img src={success}
                        alt="Certificado activo"
                        sizes="(min-width: 600px) 200px, 50vw"
                        className={classes.imagen}
                  />
                  <Typography variant="h5" gutterBottom style={{textAlign:'center'}}>
                      El código proporcionado pertenece a un certificado emitido para
                        <Typography variant="h5" gutterBottom style={{fontWeight:'bold'}}> {certificado.persona} </Typography>
                      con fecha <Moment format="DD - MMMM - YYYY" locale={'es'} tz={'America/Guayaquil'}>{certificado.fecha}</Moment> en
                      virtud de <Typography variant="h5" gutterBottom style={{fontWeight:'bold'}}> {certificado.proposito}</Typography>
                      <Typography color="textSecondary" style={{textAlign:'center'}}>
                         {certificado.descripcion}
                      </Typography>
                  </Typography>

                  <p style={{textAlign:'center'}}>Código del certificado {code}</p>
                  </React.Fragment>
                 }
                 {resultado===false &&
                    <React.Fragment>
                    <img src={error}
                          alt="Certificado activo"
                          sizes="(min-width: 300px) 200px, 50vw"
                          className={classes.imagen}
                    />
                    <Typography variant="h5" gutterBottom style={{textAlign:'center'}}>
                      El código proporcionado no pertenece a ningún certificado emitido
                    </Typography>
                    </React.Fragment>
                  }

                </Grid>
              </Grid>
            </React.Fragment>
          }
      />
  );
}


export default Validar;
