import React from 'react';


import writing from '../../assets/images/writing.jpg';

export const colorPrimary='#5B2A8C';
export const colorSecondary='#F21B7F';
export const colorTerciario='#BF1F87';
export const IMPUESTO = 12; //Porcentaje de impuesto en Ecuador%
export const URL_APP = 'https://academia.xperting.club/' ;



//export const IVA= 12;
export const mainFeaturedPost = {
  title: 'Es la academia de aprendizaje abierto',
  description:
    "Aprende con expertos en diversas ramas a iniciar, potenciar y fortalecer tu idea de negocio para hacerla rentable .",
  image: writing , //process.env.PUBLIC_URL +
  imgText: 'main image description',
  linkText: 'Conoce más…',
};



export const sections = [
  { title: 'Validar Certificado', url: '#' },
  { title: 'Sé Instructor', url: 'instructor' },
  { title: 'Membresía', url: 'membresia' },
];

export const getValueByKey = (id, items)=>{
  let result="";
  return items.filter((data)=>{
    if(data.id === id){
      result+= data.nombre;
    }
  });
  //return result;
}

export const meses= ["Seleccione ","Enero","Febrero",
              "Marzo", "Abril","Mayo", "Junio",
              "Julio", "Agosto","Septiembre",
              "Octubre", "Noviembre","Diciembre"
            ];


//function rand (){
  //  return Math.round(Math.random() * 20) - 10;
//}

export function getModalStyle (){
    const top = 50 ;  //+ rand()
    const left = 50 ; //+ rand()
    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
