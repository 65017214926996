import React,{Component} from 'react';
import { connect } from 'react-redux';
import { setUserData } from '../../components/redux/actions/ActionsUserData';
import MyStorage from '../../components/helpers/MyStorage';
import { withStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import WebApi from '../../components/helpers/WebApi';

const useStyles = theme => ({
  form: { border:0, },
  submit: { border:0, },
  forgot: {paddingTop: 20},

});


class LoginForm extends Component {

  state ={
      email : '',
      password: ''
  }


  handleChange = (e) => {
      this.setState({
          [e.target.id]: e.target.value
      })
  }
  handleSubmmit = (e) => {
      e.preventDefault();
      console.log(this.state);
      new WebApi().userLogin(this.state.email, this.state.password).then(result => {
        setTimeout(() => {
            this.processLoginResponse(result);
        }, 300)
      })
  }

  processLoginResponse = (result) => {
      //console.log(result.data);
      let { data } = result;
      if (data.result) {
          //se redirecciona automaticamente
          let user = data.data;
          this.props.setUserData(user);
          user = JSON.stringify(data.data);
          new MyStorage().setUserInfo(user);
      } else {
          alert(data.message);
      }
  }

  form = () => {
    const { classes } = this.props;
    return (

      <form className={classes.form} noValidate action="#!" onSubmit={this.handleSubmmit}>
       <TextField
         variant="outlined"
         margin="normal"
         required
         fullWidth
         id="email"
         label="Correo Electrónico"
         name="email"
         autoComplete="email"
         autoFocus
         onChange={this.handleChange}
       />
       <TextField
         variant="outlined"
         margin="normal"
         required
         fullWidth
         name="password"
         label="Contraseña"
         type="password"
         id="password"
         autoComplete="current-password"
         onChange={this.handleChange}
       />
       <FormControlLabel
         control={<Checkbox value="remember" color="primary" />}
         label="Recuérdame"
         onChange={this.handleChange}
       />
       <Button
         type="submit"
         fullWidth
         variant="contained"
         color="primary"
         className={classes.submit}
       >
         Inicia Sesión
       </Button>
       <Grid container className={classes.forgot}>
         <Grid item xs>
           <Link href="#" variant="body2">
             ¿Olvidó su contraseña?
           </Link>
         </Grid>
         <Grid item>
           <Link href="#" variant="body2">
             {"¿No tienes una cuenta? Regístrate"}
           </Link>
         </Grid>
       </Grid>

     </form>
    )
  }



  render (){
    return (
      <main>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-6 login-section-wrapper">

              <div className="login-wrapper my-auto">

                {this.form()}

              </div>
            </div>

          </div>

        </div>
      </main>
    )
  }


}

const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}

const actions = {
    setUserData
}


//export default Login;
export default connect(mapStateToProps, actions)(withStyles(useStyles, { withTheme: true })(LoginForm));

/*
<!--<a href="#!" className="forgot-password-link">Forgot password?</a> -->
<!--<p className="login-wrapper-footer-text">Don't have an account? <a href="#!" className="text-reset">Register here</a></p>-->
*/
