import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import WebApi from '../../../../components/helpers/WebApi';
import { Alert } from '@material-ui/lab';

export default function FormDialog(props) {
  // const [open, setOpen] = React.useState(false);
  const [cedula, setCedula ] = React.useState('');
  const [messaje, setMessage] = React.useState('');
  const { dni , download}= props;
  const handleClose = () => {
    props.setClose();
  };

  const descargar = async () => {
   if(download===""){
     setMessage('No disponemos de un certificado para descarga.');
     return;
   }
   var url= new WebApi()._IMAGES_URL+'certificados/'+ download;
   window.open(url, '_blank');
    //0750629776
 };

  const handleConfirm = () => {
    setMessage('');
    if(cedula===dni){
      //window.open(new WebApi()._IMAGES_URL+'certificados/'+ download,'_blank')
      descargar();
      setCedula('');
      handleClose();
    } else {
      setMessage('La cédula ingresada es incorrecta.')
    }
  };

  return (
    <div>
      <Dialog open={props.open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirmación de descarga</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Para descargar el certificado, favor ingrese la cédula del alumno para validar sus datos.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Cédula del alumno"
            type="text"
            style={{width:'100%'}}
            value={cedula}
            fullwidth
            onChange={(e)=>setCedula(e.target.value)}
          />
          {messaje &&
            <Alert severity="error"  style={{marginBottom:20}}>{messaje}</Alert>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleConfirm} color="primary">
            Descargar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
