import React, {Component} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import Pagination from '@material-ui/lab/Pagination';
import { Alert } from '@material-ui/lab';
import GetAppIcon from '@material-ui/icons/GetApp';
import WebApi from '../../../components/helpers/WebApi';
import Spinner from '../../../components/common/Spinner';
import Table from '../../../components/common/Table';
import ConfirmDownload from './Elements/ConfirmDownload';

const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

class DescargarCerts extends Component {
  PAGE_NUM=1;
  state ={
      search : '',
      certs: [],
      total: 0,
      perPage: 0,
      item_selected: false,
      showEdit:false,
      show_loader: false,
      columns: ['id','dni', 'certificado','persona','fecha','proposito','descripcion', 'descargar'],
      tableHead: ['id','dni','certificado' ,"En favor de",  "Fecha", "Proposito", "Descripcion", 'Descargar'],
      mensaje:'',
      open: false,
      dni:'',
      download: '',
  }

  componentDidMount(){
    this.handleGetCerts();
  }

  handleChange = (e) => {
      this.setState({
          [e.target.id]: e.target.value
      })

  }

  handleSubmit = (e) => {
     this.PAGE_NUM=1;
      e.preventDefault();
      this.setState({
          [e.target.id]: e.target.value,
          certs:[]
      }, () => this.state.search.length >2 || this.state.search.length ===0  ? this.handleGetCerts(): null);

  }

  handleGetCerts = () => {
      this.setState({ show_loader:true, mensaje:'', certs: [], total: 0, perPage: 0, });
      new WebApi().getCertificadosUnicosTiaPatty(this.state.search, this.PAGE_NUM).then(result => {
            this.processGetCertsResponse(result);
      })
  }

  processGetCertsResponse = (result) => {
      if (result.no_result) {
          return
      }
      this.setState({show_loader:false});
      let { data } = result;
      if (data.result) {

          this.setState({total: data.total, certs: data.certificados, perPage: data.perPage});
      } else {
          this.setState({mensaje:data.message});
      }
  }

   filtrarDatos= (list, columns) =>{
      return list.map (elem => {
        return columns.map(column=> {
          if(typeof elem[column]!== 'undefined'){
            return elem[column]
          }
        });
      });
   }

   setOpen = (dni='', download='') =>{
     this.setState({open: true, dni:dni, download: download, mensaje:''})
   }

   setClose=()=>{
     this.setState({open: false, dni:'', download: ''})
   }

   confirmar=()=>{
     let { open, dni, download} = this.state;
     return (
         <ConfirmDownload open={open} setClose={this.setClose} dni={dni} download={download}/>
     )
   }

  content = () => {
    //let adjacents  = 4; //brecha entre páginas después de varios adyacentes
    //let offset = (this.PAGE_NUM - 1) * this.state.perPage;
    let total_pages = Math.ceil(this.state.total/this.state.perPage);
    let {tableHead, certs, total, columns} = this.state;
    return (
        <React.Fragment>
          <Spinner visible={this.state.show_loader}/>
          {certs.length > 0 ?
            <Table
              tableHead={tableHead}
              tableData={certs}
              columns={columns}
              ignoreCells={"id,dni,certificado"}
              edit={this.editUser}
              customRow={(row, columnName)=>{
                  return columnName==="Descargar" ?
                    <GetAppIcon onClick={()=>this.setOpen(row[1],row[2])}/>  : ""
                }
              }
            />
            :
            <Typography color="textSecondary" align="center">
              No hay resultados para mostrar
            </Typography>
          }
          {this.confirmar()}
          <Pagination count={total_pages} page={this.PAGE_NUM} color="primary" onChange={this.load_next} style={{marginTop:20}}/>
          { certs.length > 0 &&
            <div style={{ textAlign:"right",marginTop: 30 }}>Total de certificados: {total}</div>
          }
        </React.Fragment>
      )
  }

  //editUser = (item)=> this.setState({item_selected:item.id, showEdit: true});
  editUser = (id)=>  this.setState({item_selected:id, showEdit: true});


  hideModal = () => {
    this.setState({
      showEdit:false,
    })
  }

  render (){

    const { classes } = this.props;
    return (
      <React.Fragment>
      {this.state.mensaje!=="" && <Alert severity="info" style={{marginBottom:20}}>{this.state.mensaje}</Alert>}

        <Paper className={classes.paper}>

            <AppBar className={classes.searchBar} position="static" color="default" elevation={0}>

              <Toolbar>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <SearchIcon className={classes.block} color="inherit" />
                  </Grid>
                  <Grid item xs>
                    <TextField
                      fullWidth
                      placeholder="Busca por beneficiario, o  UID de usuario"
                      InputProps={{
                        disableUnderline: true,
                        className: classes.searchInput,
                      }}
                      name='search'
                      id='search'
                      value={this.state.search}
                      onChange={this.handleSubmit}
                    />
                  </Grid>
                  <Grid item>

                    <Tooltip title="Reload" onClick={this.refresh}>
                      <IconButton>
                        <RefreshIcon className={classes.block} color="inherit" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Toolbar>
            </AppBar>

          <div className={classes.contentWrapper}>
            { this.content()}
          </div>
        </Paper>

      </React.Fragment>


    )
  }
  //<Paginate tpages={total_pages}  page={this.PAGE_NUM} adjacents={adjacents} load_next={this.load_next}/>

  refresh = () => {
    this.PAGE_NUM=1;
    this.setState({
      search:'',
    }, ()=>this.handleGetCerts()
    )
  }

  load_next = (event,page) => {
    this.PAGE_NUM= page;
    this.handleGetCerts();
  }

}


export default withStyles(styles)(DescargarCerts);
