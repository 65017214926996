import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Main from '../Main';


const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },

  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },

}));

const tiers = [
  {
    title: 'Free',
    price: '0',
    description: ['Cursos introductorios', 'Noticias de la App', 'Uso limitado de Apps','','',''],
    buttonText: 'Registro gratuito',
    buttonVariant: 'outlined',
  },
  {
    title: 'Membresía PRO',
    price: '100',
    description: [
      'Acceso ilimitado a cursos',
      'Acceso ilimitado a eventos',
      'Acompañamiento de profesionales',
      'Marketing y Finanzas',
      'Identidad Corporativa',
      'Tu propio Sitio Web',
      'Tarjeta de presentación virtual',
      'Acceso VIP a APPs',
      'Descuentos con filiales'

    ],
    buttonText: 'Empecemos',
    buttonVariant: 'contained',
  },
  {
    title: 'Solidario',
    subheader: 'Para negocios emergentes',
    price: '20',
    description: [
      '50% Descuento en cursos',
      '50% Descuento en eventos',
      'Tarjeta de presentación virtual',
      'Acceso total a APPs',
      'Asesoría a negocios',
    ],
    buttonText: 'Contáctanos',
    buttonVariant: 'outlined',
  },
];


export default function Membresia() {
  const classes = useStyles();

  return (
    <Main content={
      <React.Fragment>

        {/* Hero unit */}
        <Container maxWidth="sm" component="main" className={classes.heroContent}>
          <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
            Membresía Xperting
          </Typography>
          <Typography variant="h5" align="center" color="textSecondary" component="p">
            Los Miembros Xperting, reciben acompañamiento TOTAL de Profesionales
             en Planificación, Administración, Finanzas, Mercadología y Tecnologogía
            para EMPEZAR o HACER CRECER CUALQUIER NEGOCIO. Conoce si tu idea es viable o en qué necesitas mejorarla.
            Además, accedes a todos los beneficios del Club Xperting (Eventos, Descuentos con filiales, y más).
          </Typography>
        </Container>
        {/* End hero unit */}
        <Container maxWidth="md" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {tiers.map((tier) => (
              // Enterprise card is full width at sm breakpoint
              <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                <Card>
                  <CardHeader
                    title={tier.title}
                    subheader={tier.subheader}
                    titleTypographyProps={{ align: 'center' }}
                    subheaderTypographyProps={{ align: 'center' }}
                    action={tier.title === 'Pro' ? <StarIcon /> : null}
                    className={classes.cardHeader}
                  />
                  <CardContent>
                    <div className={classes.cardPricing}>
                      <Typography component="h2" variant="h3" color="textPrimary">
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        /mes
                      </Typography>
                    </div>
                    <ul>
                      {tier.description.map((line) => (
                        <Typography component="li" variant="subtitle1" align="center" key={line}>
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button fullWidth variant={tier.buttonVariant} color="primary">
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </React.Fragment>
    }/>
  );
}
