import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import Skeleton from '@material-ui/lab/Skeleton';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import WebApi from '../../components/helpers/WebApi';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: 20,
  },
  slide:{
    height:180,
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  button:{
    marginBottom: 16,
    marginRight: 16,
    width:48,
    height:48,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: '#E8EAF0',
    borderRadius: 2,
    backgroundColor: 'transparent',

  },
  buttonIcon:{
    color:'#1d2671',
  },
  buttonFile:{
    width:'100%',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '50%',
  },
  textFile:{
    float:'left',
    width: '50%',
  }
}));

export default function CursoCinta() {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [cursos,setCursos]= React.useState([]);

  const getCursos = () => {
    new WebApi().getCursosMejores('',1).then(result => {
      processGetCursos(result);
    })
  }

  const processGetCursos = (result) => {
    setLoading(true);
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        setCursos(data.cursos);
        setLoading(false);
        //this.setState({total: data.total, users: this.filtrarDatos(data.cursos, this.state.columns), perPage: data.perPage});
    }
  }

  useEffect(() => {
     getCursos();
  },[0]);

  return (

      loading ?
      (
        <Grid container >
          <Skeleton width="80%" style={{ marginTop: 10, marginBottom: 10 }}/>
        {
          [1,2].map((i) => (
              <Paper index={i} key={i} className={classes.paper} style={{marginTop:10}}>
                <Grid container spacing={2} >
                  <Grid item>
                    <ButtonBase className={classes.image}>
                    <Skeleton variant="rect" width={'100%'} height={'100%'} />
                    </ButtonBase>
                  </Grid>
                  <Grid item xs={12} sm container style={{width: 300}}>
                    <Grid item xs container direction="column" spacing={2}>
                      <Grid item xs>
                        <Skeleton style={{ marginTop: 20 }} />
                        <Skeleton width="80%" style={{ marginBottom: 20 }}/>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
        ))}
        </Grid>
      )
      :
      <CarouselProvider
        className={classes.root}
        totalSlides={3}
        visibleSlides={2}
        naturalSlideWidth={500}
        naturalSlideHeight={180}
      >
      <div >
        <div className={classes.textFile}>
          <Typography gutterBottom variant="subtitle1" style={{textAlign: 'left'}}>
            Estos son los cursos más populares
          </Typography>
        </div>
        <div className={classes.buttonFile}>
          <ButtonBack className={classes.button}><ArrowBackIcon className={classes.buttonIcon}/></ButtonBack>
          <ButtonNext className={classes.button}><ArrowForwardIcon className={classes.buttonIcon}/></ButtonNext>
        </div>
      </div>
      <Slider className={classes.slide}>
        {
          cursos.map((post,i) => (
          <Slide index={i} classNameVisible={classes.slide}>
            <Paper className={classes.paper} onClick={()=>window.open('/curso/'+ post.alias, '_self')} >
              <Grid container spacing={2}>
                <Grid item>
                  <ButtonBase className={classes.image}>
                    <img className={classes.img} alt="Imagen Curso" src={new WebApi()._IMAGES_URL + post.foto} />
                  </ButtonBase>
                </Grid>
                <Grid item xs={12} sm container >
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs>
                      <Typography gutterBottom variant="subtitle1" style={{fontWeight:'bold'}}>
                        {post.nombre}
                      </Typography>
                      {
                      /*<Typography variant="body2" gutterBottom>
                        Full resolution 1920x1080 • JPEG
                      </Typography>*/
                      }
                      <Typography variant="body2" color="textSecondary">
                        {post.categoria}
                      </Typography>
                    </Grid>

                  </Grid>
                  {
                    /*
                    <Grid item>
                      <Typography variant="subtitle1">$19.00</Typography>
                    </Grid>
                     */
                  }
                </Grid>
              </Grid>
            </Paper>
          </Slide>
        ))
        }
        </Slider>

      </CarouselProvider>


  );
}
