import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import Button from '@material-ui/core/Button';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WebApi from '../../components/helpers/WebApi';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    //margin:theme.spacing(6,0),
    paddingBottom: 50,
  },
  card: {
    maxWidth: 345,
    cursor: 'pointer',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
  title: {
    fontWeight: 700,
    fontSize: 17,
  },
  subtitle1:{
    color: "#8D8D9D",
    fontSize: 14,
  },
  precio: {
    fontWeight: 900,
    fontSize: 18,
    color: '#E6215D',
    float:'left',
    marginRight: 10,
  },
  tachado :{
    textDecoration: 'line-through',
    marginTop:4,
    color: '#8D8D9D',
  },
  tachado_blanco: {
    marginTop:4,
    color: '#fff',
  }
}));

  export default function FeedCursos() {
    const classes = useStyles();
    const [expanded] = React.useState(false);
    const [show_loader,setShowLoader]= React.useState( false );
    const [page,setPage]= React.useState(1);
    const [cursos,setCursos]= React.useState([]);

    const getCursos = () => {
      setShowLoader(true);
      new WebApi().getCursos('',page).then(result => {
        processGetCursos(result);
      })
    }

    const processGetCursos = (result) => {
      setShowLoader(false);
      if (result.no_result) {
          return
      }
      let { data } = result;
      if (data.result) {
          setCursos(data.cursos);
          //this.setState({total: data.total, users: this.filtrarDatos(data.cursos, this.state.columns), perPage: data.perPage});
          setShowLoader(false);
      }
    }

    useEffect(() => {
       getCursos();
    },[0]);

    return (
    <Grid className={classes.root} container direction="row" spacing={3} >
      { !show_loader ?
        cursos.map((post,i) => (
        <Grid item  xs key={i}>
          <Card className={classes.card} onClick={()=>window.open('/curso/'+ post.alias, '_self')} >
            <CardHeader
              action={
                <IconButton aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
              }
              title= {post.nombre}
              subheader={post.categoria}
              classes={{title: classes.title, subheader: classes.subtitle1}}
            />
            <CardMedia
              className={classes.media}
              image={new WebApi()._IMAGES_URL + post.foto}
              title="Paella dish"
            />

              <CardContent>
                <div className={classes.precio}>
                  US${parseInt(post.preciopromocional)? post.preciopromocional : post.precio}
                </div>
                <div  className={ parseInt(post.preciopromocional)?  classes.tachado : classes.tachado_blanco}>
                  US${post.precio}
                </div>
              </CardContent>


            <CardActions disableSpacing>
              <IconButton aria-label="add to favorites">
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share">
                <ShareIcon />
              </IconButton>
              <div
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}

              >
              <Button   variant="contained" color="primary" endIcon={<ShoppingCartIcon color={"#fff"}/>}>
                Comprar ahora
              </Button>
              </div>
            </CardActions>
          </Card>
          </Grid>
        ))
        :
        (
            <React.Fragment>
            {
              [1,2,3].map((i) => (
              <Grid item  xs key={i}>
                <Card className={classes.card}>
                  <Skeleton width="80%" style={{ marginTop: 10, marginBottom:10 }} />
                  <Skeleton variant="rect" width={'100%'} height={200} />
                  <Skeleton style={{ marginTop: 20 }} />
                  <Skeleton width="80%" style={{ marginBottom: 20 }}/>
                </Card>
              </Grid>
            ))}
            </React.Fragment>
          )
      }

    </Grid>
  );
}
