import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import TimerIcon from '@material-ui/icons/Timer';
import ComputerIcon from '@material-ui/icons/Computer';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import {colorPrimary, colorSecondary} from '../../../components/helpers/Constantes';

 const beneficiosPost = [
  {
    title: 'Nuevos cursos todos los meses',
    description:
      'Todo lo que necesitas saber para mejorar en gestión de negocios.',
    icon: <React.Fragment><ComputerIcon /></React.Fragment>,
  },
  {
    title: 'Material de apoyo',
    description:
      'Guías, E-books, plantillas y material exclusivo para potenciar la aplicación de los conocimientos',
    icon: <React.Fragment><WorkOutlineIcon /></React.Fragment>,
  },
  {
    title: 'Avanza a tu ritmo',
    description:
      'Aprende en donde estés. Con solo 15 minutos al día, aprendes nuevos cursos en 1 semana.',
    icon: <React.Fragment><TimerIcon /></React.Fragment>,

  },
  {
    title: 'Instructores expertos',
    description: 'Encuentra el instructor adecuado para ti y guía personalizada',
    icon: <React.Fragment><AssignmentIndIcon /></React.Fragment>,
  }
];

const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  cardPurple:{
    backgroundColor: colorPrimary,
    color: '#fff',
  },
  cardRed:{
    backgroundColor: colorSecondary,
    color: '#fff',
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
  icon:{
    color: '#fff',
    marginLeft:10,
  }
});

export default function Beneficios(props) {
  const classes = useStyles();
  const { post } = props;

  return (
    <React.Fragment>
      {beneficiosPost.map((post, index) => (

        <Grid item xs={12} md={5} key={index}>
          <CardActionArea component="a" href="#">
            <Card className={[classes.card, index%2==0 ? classes.cardPurple: classes.cardRed]}>
              <Hidden xsDown>
                <IconButton className={[classes.icon, 'iconFeatures']} >
                {post.icon || null}
                </IconButton>
              </Hidden>
              <div className={classes.cardDetails}>
                <CardContent>
                  <Typography component="h2" variant="h5">
                    {post.title}
                  </Typography>
                  <Typography variant="subtitle1" paragraph>
                    {post.description}
                  </Typography>
                </CardContent>
              </div>

            </Card>
          </CardActionArea>
        </Grid>
      ))}
    </React.Fragment>
  );
}

Beneficios.propTypes = {
  post: PropTypes.object,
};
