import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
//import Container from '@material-ui/core/Container';
import Image from 'material-ui-image';
import WebApi from '../../../components/helpers/WebApi';
import Spinner from '../../../components/common/Spinner';
import MediaControlCard from '../../../components/common/MediaControlCard';
import SocialShare from '../../../components/common/SocialShare';
import Main from '../Main';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
    justifyContent: "center",
  },
  columns: {
    padding: '20px !important',
  },
  social:{
    marginRight:10,
    height:36,
  },
  socialTitle: {
    height: 160,
    paddingTop:20,
    paddingLeft:25,
    background:'#fff',
    paddingBottom: 20,
  },
  latest: {
    marginTop:30,
  },
  latestChild: {
    marginBottom: 20,
  }
}));


export default function Noticia({match}) {
  const classes = useStyles();
  const [noticia, setNoticia] = React.useState([]);
  const [latest, setLatest] = React.useState([]);
  const [show_loader, setShow_loader] = React.useState(false);

  const getLatest = () => {
    new WebApi().getNoticiasLatest().then(result => {
      processGetLatest(result);
    })
  }

  const processGetLatest = (result) => {
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.length>0) {
        setLatest(data);
    }
  }

  const getNoticia = () => {
    setShow_loader(true);
    new WebApi().getNoticia(match.params.id).then(result => {
      processGetNoticia(result);
    })
  }

  const processGetNoticia = (result) => {
    setShow_loader(false);
    if (result.no_result) {
        return
    }
    let { data } = result;
  //  console.log('result ', data);
    setNoticia(data);
    setShow_loader(false);
  }

  const openNotice=(id)=>{
    window.open('/noticia/'+id,'_self')
  }

   useEffect(() => {
      getNoticia(match.params.id);
      getLatest();
  },[0]);
  const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  //const match = this.props.match;
    //match.params.id
  return (
    <Main content={
      <React.Fragment>
        <Spinner visible={show_loader}/>

        <Grid container spacing={1} direction="row">
          <Grid  item sm={8} xs={12} className={classes.columns}>
          {noticia.title ?
          <React.Fragment>
          <Typography component="h1" variant='h2'>
            {noticia.title && new WebApi().descodificarEntidad(noticia.title.rendered)}
          </Typography>
          <Grid container spacing={1} direction="row">
            <Grid container item sm={6} xs={12} spacing={3} justify="center" >
              <Image
                src={noticia? noticia.jetpack_featured_media_url: ''}
                className={'post-image'} style={{paddingTop:20}}
                imageStyle={{position:'relative',margin:'auto', height:'auto'}} />
            </Grid>
            <Grid container item sm={6} xs={12} spacing={3} style={{padding:30, fontStyle:'oblique'}}>
              <Typography variant="subtitle1" paragraph>
                {noticia.excerpt && renderHTML(noticia.excerpt.rendered )}
              </Typography>
            </Grid>
          </Grid>

          <Typography variant="subtitle1" paragraph style={{paddingTop: 20, }}>
            {noticia.content && renderHTML(noticia.content.rendered )}
          </Typography>
          </React.Fragment>
        : null
        }
          </Grid>
          <Grid item sm={4} xs={12} className={classes.columns} >
              <SocialShare link={noticia.link}/>
              <Grid container spacing={1} className={classes.latest}>
              {
               latest.map((post,i) => (
                 <Grid item sm={12} xs={12} className={classes.latestChild} >
                    <MediaControlCard key={i}
                      title={post.title && new WebApi().descodificarEntidad(post.title.rendered)}
                      image={post? post.jetpack_featured_media_url: ''}
                      onClick={()=>openNotice(post.id)}
                    />
                  </Grid>
                ))
              }
              </Grid>
          </Grid>
        </Grid>

      </React.Fragment>
      }
    />
  );
}
