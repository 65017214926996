import React,{useEffect} from 'react';
import {createMuiTheme, ThemeProvider,  makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';

import {mainFeaturedPost, sections} from '../../components/helpers/Constantes';
import Spinner from '../../components/common/Spinner';
import WebApi from '../../components/helpers/WebApi';
import Header from '../Header';
import Footer from '../Footer';
import MainFeaturedPost from './Inicio/MainFeaturedPost';
import FeaturedPost from './Inicio/FeaturedPost';
import Beneficios from './Inicio/Beneficios';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
    justifyContent: "center"
  },
}));

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Karla',
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': 'Karla',
      },
    },
  },
});


export default function Main(props) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Header title="Geek Learning" sections={sections} />
          <main>
            {props.content}
          </main>
        <Footer title="Logo" description="Promovemos el desarrollo de negocios." />
      </ThemeProvider>
    </React.Fragment>
  );
}
