import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Moment from 'react-moment';
import 'moment/locale/es';
import 'moment-timezone';
import { useLocation } from "react-router-dom";

import WebApi from '../../../components/helpers/WebApi';
import Spinner from '../../../components/common/Spinner';
import success from '../../../assets/images/tenor.gif';
import error from '../../../assets/images/error.gif';

import {colorPrimary,colorTerciario} from '../../../components/helpers/Constantes';


const padd= window.innerWidth > 1200 ? "20% !important": '20px !important';
const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  columns: {
    paddingLeft: padd,
    paddingRight: padd,
  },
  imagen:{
    margin: 'auto',
    display:'block',
    maxWidth: 400,
  },
  content:{
    paddingBottom:40,
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

//const posts = [post1, post2, post3];
const posts = [];

function Respuesta(props) {
  let query = useQuery();

  const classes = useStyles();


  const [certificado, setCertificado] = React.useState([]);
  const [resultado, setResultado] = React.useState('');
  const [show_loader, setShow_loader] = React.useState(false);

  const getCertificado = () => {
    setShow_loader(true);
    new WebApi().getCertificadoValidate().then(result => {
      processGetCurso(result);
    })
  }

  const processGetCurso = (result) => {
    setShow_loader(false);
    setResultado(''); //BORRAR DATOS
    if (result.no_result) {
        return
    }
    let { data } = result;
    setResultado(data.result); //marcar si fue o no fue emitido
    if(data.result){
      setCertificado(data.data);
      setShow_loader(false);
    }
  }

   useEffect(() => {
      //getCertificado();
  },[0]);
  const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  //alert(alias);
  return (
      <React.Fragment>

          <Spinner visible={show_loader}/>
          <Grid container spacing={3} wrap="nowrap" className={classes.content} >
            <Grid  item sm  className={classes.columns}>
            {query.get("id")!==null ?
              <React.Fragment>
              <img src={success}
                    alt="Pago exitoso"
                    sizes="(min-width: 600px) 200px, 50vw"
                    className={classes.imagen}
              />
              <Typography variant="h5" gutterBottom style={{textAlign:'center'}}>
                  Su solicitud se ha procesado con éxito {query.get("id")} con tu compra {query.get("clientTransactionId")}
              </Typography>

              <p style={{textAlign:'center'}}>Código</p>
              </React.Fragment>
             :

                <React.Fragment>
                <img src={error}
                      alt="Error al pagar"
                      sizes="(min-width: 300px) 200px, 50vw"
                      className={classes.imagen}
                />
                <Typography variant="h5" gutterBottom style={{textAlign:'center'}}>
                  No se ha podido procesar su pago. Si considera que existe un error, comuníquese a los números
                </Typography>
                </React.Fragment>
              }

            </Grid>
          </Grid>
      </React.Fragment>
  );
}


export default Respuesta;

/*

<?php
$transaccion = $_GET[«id»];
$client = $_GET[«clientTransactionId»];
?>

Debes hacer una llamada POST a la siguiente dirección:  https://pay.payphonetodoesposible.com/api/button/V2/Confirm con el parámetro de ID y ClientTransactionID en tipo Json. No olvides adjuntar en el header tu token de autenticación.

{
«id»: int,
«clientTxId»: «string»
}

statusCode 	Código de estado de la transacción. 2=Cancelado.03=Aprobada
transactionStatus 	Estado de la transacción.
clientTransactionId 	Identificador de transacción que tu enviaste en la petición.
authorizationCode 	Código de autorización bancario.
transactionId 	Identificador de transacción asignado por PayPhone.
email 	El correo electrónico empleado por el usuario para el pago.
phoneNumber 	Número de teléfono empleado por el usuario para el pago.
document 	Número de cédula empleado por el usuario para el pago.
amount 	Monto total pagado.
cardType 	Tipo de tarjeta empleada, puede ser crédito o débito.
cardBrandCode 	Código de la marca de la tarjeta.
cardBrand 	Marca de la tarjeta.
bin 	Primeros 6 dígitos de la tarjeta empleada.
lastDigits 	Últimos dígitos de la tarjeta empleada.
deferredCode 	Código de diferido empleado por el usuario. Aquí puedes conocer más de los diferidos.
deferredMessage 	Mensaje del diferido.
deferred 	Variable boleana que indica si se uso un diferido o no.
message 	En caso de error se muestra el error en este parámetro. Puedes consultar el catálogo de errores haciendo click aquí.
messageCode 	Código de mensaje.
currency 	Moneda empleada para el pago.
taxes 	Arreglo con todos los impuestos pagados, con los campos: («name»,»amount», «value», «tax»)
recap 	Parámetro de identificación bancario.
optionalParameter1 	Parámetro opcional
*/
