import React,{Component} from 'react';
import { withStyles } from "@material-ui/core/styles";
// ES6 Modules or TypeScript
import Swal from 'sweetalert2';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import Modal from '@material-ui/core/Modal';
import { connect } from 'react-redux';
import WebApi from '../../../components/helpers/WebApi';
import {getModalStyle} from  '../../../components/helpers/Constantes';
import {CustomInput, ComboBox} from '../../../components/common/Parts';
import Spinner from '../../../components/common/Spinner';
import Entidades from '../Users/Entidades';
import CropFreeIcon from '@material-ui/icons/CropFree';
import Typography from '@material-ui/core/Typography';
import UploadButton from './Elements/UploadButton';

import QRCode from 'qrcode.react';
// CommonJS
//const Swal = require('sweetalert2');

const useStyles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  modal: {
       display: 'flex',
       alignItems: 'center',
       justifyContent: 'center',
   },
   paper: {
       position: 'absolute',
       width: theme.spacing(100),
       backgroundColor: theme.palette.background.paper,
       boxShadow: theme.shadows[5],
       padding: theme.spacing(2, 4, 4),
       borderRadius:10,

   },
 });

class Crear extends Component {

  constructor(props) {
    super(props);
    // crear ref
    //this.destinatarios = React.createRef();
    this.actualizarArchivo = this.actualizarArchivo.bind(this)
  }

  state={
    id:"",
    destinatario:"",
    descripcion:"",
    proposito:"",
    programa: "",
    certificado:"",
    fecha:"",
    show_loader:false,
    show_qr: false,
  }

  ITEM=this.props.item || false;

  componentDidMount(){
    this.getCertificado();
  }

  setAutor=(destinatario)=>{
    this.setState({destinatario:destinatario});
  }

  formulario = ()=> {
    return (
      <Grid  container>
        <Grid item md={6} sm={6} >
          <Entidades setAutor={this.setAutor} ref="destinatarios"/>
        </Grid>
        <Grid item md={6} sm={6}>
          <CustomInput
            name={"programa"}
            label={"Programa o Jornada"}
            onChangeText={this.handleChange}
            value={this.state.programa}
            required
          />
        </Grid>
        <Grid item md={6} sm={6}  >
          <CustomInput
            type="date"
            name={"fecha"}
            label={"Fecha de emisión"}
            onChangeText={this.handleChange}
            value={this.state.fecha}
            required
          />
        </Grid>
        <Grid item md={6} sm={6}>
          <CustomInput
            name={"proposito"}
            label={"Proposito"}
            onChangeText={this.handleChange}
            value={this.state.proposito}
            required
          />
        </Grid>
        <Grid item md={12} sm={12}>

          <CustomInput
            multiline='true'
            type='textarea'
            name={"descripcion"}
            label={"Descripción"}
            onChangeText={this.handleChange}
            value={this.state.descripcion}
            required
          />
        </Grid>
      </Grid>
    )
  }

  actualizarArchivo=(file)=> {
    this.setState({ certificado: file });
  }

  render(){
    const {user, classes, hideModal, open}= this.props;
    const modalStyle = getModalStyle();
    return(
      <Modal
        disablePortal
        onBackdropClick={hideModal}
        open={open}
        onClose={hideModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>

          <CloseIcon onClick={hideModal} className={'cerrar_modal'}/>

          <form action="#!" onSubmit={this.handleSubmmit}>
            {this.state.mensaje!=="" && <div className="alert alert-info">{this.state.mensaje}</div>}
              <Spinner visible={this.state.show_loader}/>
              <Grid
                container
              >
                <Grid md={7} sm={7} >
                  {this.formulario()}
                </Grid>
                <Grid md={5} sm={5} style={{marginTop:20, paddingLeft:10}}>
                  {
                    this.ITEM && <QRCode value={"https://academia.xperting.club/validar/CER_EMP_00"+this.ITEM}  size={280}/>
                  }
                  {this.ITEM &&
                    <UploadButton item={this.ITEM}  file={this.state.certificado} actualizarArchivo={this.actualizarArchivo} /> }
                </Grid>
              </Grid>

              <div className={classes.root}>
                <Button variant="contained" color="primary" type="submit" >Guardar</Button>
                {hideModal &&  <Button variant="contained" onClick={hideModal}>Cancelar</Button>}
                {this.ITEM && this.ITEM!==user.id &&
                  <Button variant="contained" color="secondary" onClick={this.alertConfirm}>Eliminar</Button>}
                {!this.ITEM && <Button variant="contained" onClick={this.resetForm}>Limpiar</Button>}
              </div>
          </form>
        </div>
      </Modal>
    );
  }

  handleChange = (e) => {
    let name=e.target.name;
    this.setState({
        [name]: e.target.value
    });
  }

  handleSubmmit = (e) => {
    e.preventDefault();
    let { destinatario, proposito, programa, descripcion, fecha} =this.state;
    if(destinatario.trim() < 1 ) {
      alert("Escriba el nombre");
      return;
    }
    if(proposito.trim() < 2 ) {
      alert("Escriba el apellido");
      return;
    }

    if(programa.trim() < 2 ) {
      alert("Escriba la jornada");
      return;
    }

    if(proposito.trim() < 2 ) {
      alert("Escriba el proposito");
      return;
    }

    if(descripcion.trim() < 2 ) {
      alert("Escriba la cedula");
      return;
    }
    if(fecha.trim() < 2 ) {
      alert("Escriba el teléfono");
      return;
    }

    this.setState({show_loader:true});
      new WebApi().setCertificado( this.ITEM, destinatario, proposito, programa, descripcion, fecha).then(result => {
        setTimeout(() => {
            this.processSetCertificado(result);
        }, 300)
    })
  }

  processSetCertificado = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
      });
      typeof this.props.handleGetCerts !== "undefined" && this.props.handleGetCerts()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    } else {
        alert(data.message);
    }
  }

  getCertificado = () => {

    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().getCertificado(this.ITEM).then(result => {
      setTimeout(() => {
          this.processgetCertificadoResponse(result);
      }, 300)
    })
  }

  processgetCertificadoResponse = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        //se redirecciona automaticamente
        data=data.data;
        this.setState({
          id:data.id,
          destinatario: data.destinatario,
          proposito: data.proposito,
          programa: data.programa,
          descripcion: data.descripcion,
          certificado: data.certificado,
          fecha: data.fecha,
        });

        this.refs.destinatarios.selectAutor(data.destinatario,data.nombre);

    } else {
        alert(data.message);
    }
  }

  alertConfirm = ()=> {
    Swal.fire({
        title: 'Confirma',
        text: '¿Estás seguro que deseas eliminarlo?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si',
        cancelButtonText: 'No, cancelar'
      }).then((result) => {
        if (result.value) {
          this.deleteUser();
          /*
          Swal.fire(
            'Deleted!',
            'Your imaginary file has been deleted.',
            'success'
          )
        */
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log("cancelar");
        }
      })
  }

  deleteUser = () => {
    //return;
    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().deleteUser(this.ITEM).then(result => {
        this.processDeleteUser(result);
    })
  }

  processDeleteUser = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      typeof this.props.handlegetCertificados !== "undefined" && this.props.handlegetCertificados()
      typeof this.props.hideModal !== "undefined" && this.props.hideModal()
    }
  }

  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      proposito:'',
      descripcion:'',
      fecha: '',
    })
  }



}

const mapStateToProps = (state) => {
    return {
        user: state.user_data.user
    }
}
const actions = {};

export default connect(mapStateToProps, actions)(withStyles(useStyles, { withTheme: true })(Crear));
