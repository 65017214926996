import React,{Component} from 'react';
import WebApi from '../../../components/helpers/WebApi';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
//import CircularProgress from '@material-ui/core/CircularProgress';

//ENTIDADES DESTINATARIAS DE CERTIFICADO
export default class Entidades extends Component  {

  constructor(props) {
    super(props);
    this.state={
      value:  '',
      inputValue: '',
      datas:[],
    }
  }
  loading = '';
  componentDidMount(){
    this.searchUsers();
  }

  selectAutor  = (value, inputValue) => {
    this.setState({value:value, inputValue:inputValue});
  }

  searchUsers  = (value) => {
    let val = typeof value !== 'undefined'? value : this.state.inputValue;
    this.loading='Cargando...';
    new WebApi().getDestinatarios(val, 1).then(result => {
      this.processUsers(result);
    })
  }

  processUsers = (result) => {
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
      this.setState({ datas: data.destinatarios, });
      this.loading='';
    } else {
      alert(data.message);
      this.loading='No hay coincidencias';
    }

  }


  render(){
    let {value,inputValue}=this.state;
    return (
      <div>

        {
          value!=='' ?
          <React.Fragment>
            <label className={" Mui-focused Mui-focused"} data-shrink="true" htmlFor="Entidades" id="Entidades-label">Autor</label>
            <div>{` '${inputValue}'`}</div>
            <div  style={{fontSize:8}} onClick={()=>this.setState({value:''})}> Cambiar autor</div>
            <br />
          </React.Fragment>
          :
          <Autocomplete
            onChange={(event, newValue) => {
              newValue!==null &&
              this.setState({
                value: newValue.id,
                inputValue:newValue.nombre,
              });
              this.props.setAutor(newValue!==null? newValue.id: '');
            }}
            onInputChange={(event: object, value: string, reason: string) => {
              if (reason === 'input' && value.length>2)  {
                //console.log(value);
                this.searchUsers(value);
              }
            }}
             id="Entidades"
             options={this.state.datas}
             noOptionsText={this.loading}
             getOptionLabel={(option) => typeof option.nombre !=='undefined'? option.nombre + ' - ' + option.dni: ''}
             style={{ width: "95%",  marginBottom: 20,marginRight: 10, }}
             renderInput={
               (params) => {
               return (
                 <TextField
                  {...params}
                  label="Autor"
                  variant="outlined"

                 />
               )
               }
             }
           />
        }
       </div>
    );
  }

}

//, function() {
// if(reason==='input'){ this.searchUsers()
