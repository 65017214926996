import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {
  EmailShareButton,EmailIcon,
  FacebookShareButton, FacebookIcon,
  HatenaShareButton, HatenaIcon,
  InstapaperShareButton, InstapaperIcon,
  LinkedinShareButton, LinkedinIcon,
  OKShareButton,OKIcon,
  PinterestShareButton,PinterestIcon,
  PocketShareButton,PocketIcon,
  RedditShareButton,RedditIcon,
  TelegramShareButton,TelegramIcon,
  TwitterShareButton,TwitterIcon,
  VKShareButton,VKIcon,
  WhatsappShareButton,WhatsappIcon,
  WorkplaceShareButton, WorkplaceIcon
} from "react-share";

const useStyles = makeStyles((theme) => ({
  social:{
    marginRight:10,
    height:36,
  },
  socialTitle: {
    height: 160,
    paddingTop:20,
    paddingLeft:25,
    background:'#fff',
    paddingBottom: 20,
  }
}));

export default function(props){
  const classes = useStyles();
  return (
      <React.Fragment>
        <Grid container className={props.classContainer ? props.classContainer: 'MuiPaper-elevation1 '+ classes.socialTitle } spacing={1} direction="row">
          <Grid  item sm={12}>
            <Typography variant="h6" gutterBottom >
              {props.title  || 'Comparte en tus redes'}
            </Typography>
          </Grid>
          <EmailShareButton url={props.link} className={classes.social}><EmailIcon size={32}/></EmailShareButton>
          <FacebookShareButton url={props.link} className={classes.social}><FacebookIcon size={32}/></FacebookShareButton>

          <InstapaperShareButton url={props.link} className={classes.social}><InstapaperIcon size={32}/></InstapaperShareButton>
          <LinkedinShareButton url={props.link} className={classes.social}><LinkedinIcon size={32}/></LinkedinShareButton>
          <OKShareButton url={props.link} className={classes.social}><OKIcon size={32}/></OKShareButton>
          <PinterestShareButton url={props.link} className={classes.social}><PinterestIcon size={32}/></PinterestShareButton>
          <PocketShareButton url={props.link} className={classes.social}><PocketIcon size={32}/></PocketShareButton>
          <RedditShareButton url={props.link} className={classes.social}><RedditIcon size={32}/></RedditShareButton>
          <TelegramShareButton url={props.link} className={classes.social}><TelegramIcon size={32}/></TelegramShareButton>
          <TwitterShareButton url={props.link} className={classes.social}><TwitterIcon size={32}/></TwitterShareButton>
          <VKShareButton url={props.link} className={classes.social}><VKIcon size={32}/></VKShareButton>
          <WhatsappShareButton url={props.link} className={classes.social}><WhatsappIcon size={32}/></WhatsappShareButton>
          <WorkplaceShareButton url={props.link} className={classes.social}><WorkplaceIcon size={32}/></WorkplaceShareButton>
        </Grid>
      </React.Fragment>
  )
}
