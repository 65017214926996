import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Navigator from './Navigator';
import DesignArea from './DesignArea';

const useStyles = makeStyles((theme) => ({

}));

function Editor(props) {
  const defaultFields = {
    x:0,
    y: 0,
    tipo: "",
    value:"",
  };
  const classes = useStyles();
  const [elements, setElements] = React.useState([]);

  // Agregamos el nuevo grupo de campos al final de la lista
  const onAdd = (tipo, value) => {
    defaultFields.tipo=tipo;
    defaultFields.value=tipo;
    setElements([...elements, { ...defaultFields }]);
  };
  // Utilizamos el index del grupo de campos para eliminar ese grupo de la lista
  const onDelete = (indexToDelete) => {
    const newFields = elements.filter((d, index) => index !== indexToDelete);
    setElements([...newFields]);
  };

  return (
      <Grid container >
        <Navigator onAdd={onAdd} onDelete={onDelete}/>
        <DesignArea elements={elements} setElements={setElements}/>
    </Grid>
  );
}



export default Editor;
