import React,{Component} from 'react';
import clsx from 'clsx';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from "@material-ui/core/styles";
import { Alert } from '@material-ui/lab';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { convertToRaw, } from 'draft-js'; //convertFromHTML, ContentState
import MenuIcon from '@material-ui/icons/Menu';
import ClassIcon from '@material-ui/icons/Class';

import WebApi from '../../../components/helpers/WebApi';
import {CustomInput} from '../../../components/common/Parts';
import Spinner from '../../../components/common/Spinner';

const useStyles = theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  footer:{
    textAlign:'center',
    marginBottom:20,
  },
  list: {
    width: 250,
    background: '#fff',
  },
 });


class Capitulos extends Component {
  state={
    id:"",
    titulo:"", //Planteamiento de la idea
    video:"", //https://www.youtube.com/watch?v=hEt5RtogW44
    servidor:'youtube',
    descripcion:' ', //la idea de la idea
    clases:[],
    mensaje:"",
    show_loader:false,
    left:'',
  }

  ITEM=this.props.item || false;

  componentDidMount(){
    //this.getClase();
    this.getClases();
  }

  getFechaActual = () => {
    let {publicacion}=this.state;
    if(publicacion!==''){
      return publicacion;
    }
    let f = new Date();
    return f.getFullYear() + "-" + (f.getMonth() +1) + "-" + f.getDate() ;
  }

  getHoraActual = () => {
    let { publicacion_hora }=this.state;
    if(publicacion_hora!==''){
      return publicacion_hora;
    }
    let f = new Date();
    return f.getHours() + ":" + f.getMinutes()
  }

  setAutor=(autor)=>{
    this.setState({autor:autor});
  }

  render(){
    const { classes, hideModal}= this.props; //user,
    const {clases}=this.state;
    return(
      <Grid container spacing={3} direction="row" justify="center" alignItems="center">
        <form action="#!" onSubmit={this.handleSubmmit}>
          {this.state.mensaje!=="" && <Alert severity="info" style={{marginBottom:20}}>{this.state.mensaje}</Alert>}
            <Spinner visible={this.state.show_loader}/>
            <Grid container spacing={3} direction="row" justify="center" alignItems="center">
            <Grid  item sm={clases.length>0 ? 9 : 12 } xs={clases.length>0 ? 9 : 12 }>
              <CustomInput
                name={"titulo"}
                label={"Título de la Clase"}
                onChangeText={this.handleChange}
                value={this.state.titulo}
                required
                fullWidth={true}
              />
            </Grid>
              {clases.length>0 &&
                <Grid  item sm={3} xs={3}>
                  <Button onClick={this.toggleDrawer('left', true)}><MenuIcon/> Ver Clases</Button>
                  <Drawer anchor={'left'} open={this.state.left} onClose={this.toggleDrawer('left', false)}>
                    {this.list('left')}
                  </Drawer>
                </Grid>
              }
            </Grid>
            <Grid container spacing={3} direction="row" justify="center" alignItems="center">
            <Grid  item sm={8} xs={8}>
              <RadioGroup row aria-label="servidor" name="servidor" value={this.state.servidor} onChange={this.handleChange}>
                <FormControlLabel value="youtube" control={<Radio color="primary" />} label="Youtube" />
                <FormControlLabel value="vimeo" control={<Radio color="primary" />} label="Vimeo" />
                <FormControlLabel value="url" control={<Radio color="primary" />} label="Url Privada" />
              </RadioGroup>
            </Grid>
              <Grid  item sm={12} xs={12}>
                <CustomInput
                  name={"video"}
                  label={"Url del Video"}
                  onChangeText={this.handleChange}
                  value={this.state.video}
                  required
                  fullWidth={true}
                />
              </Grid>

            </Grid>
            <Grid container spacing={3} direction="row" justify="center" alignItems="center">
              <Grid  item sm={12} xs={8}>
                <CustomInput
                  name={"descripcion"}
                  label={"Descripción"}
                  onChangeText={this.handleChange}
                  value={this.state.descripcion}
                  required
                  fullWidth={true}
                  multiline={true}
                />
              </Grid>
            </Grid>
            <div className={classes.footer}>
              <Button variant="contained" color="primary" type="submit"  >Guardar</Button>
              {hideModal &&  <Button variant="contained" onClick={hideModal}>Cancelar</Button>}

              {!this.state.id && <Button variant="contained" onClick={this.resetForm}>Limpiar</Button>}
            </div>
        </form>
      </Grid>
    );
  }

toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    this.setState({'left': open });
};
list = (anchor) => {
  //['Inbox', 'Starred', 'Send email', 'Drafts']
   const {classes}= this.props;
   const {clases} = this.state
    return (
        <div
        className={clsx(classes.list)}
        role="presentation"
        onClick={this.toggleDrawer(anchor, false)}
        onKeyDown={this.toggleDrawer(anchor, false)}
      >
        <List>
          {clases.length>0 && clases.map((text, index) => (
            <ListItem button key={text.id} onClick={()=>this.getClase(text.id)}>
              <ListItemIcon>{<ClassIcon />}</ListItemIcon>
              <ListItemText primary={text.titulo} />
            </ListItem>
          ))}
        </List>

      </div>
    )
  }

  handleChange = (e) => {
    let name=e.target.name ;
    this.setState({
        [name]: e.target.value
    });
  }

  handleDescription= event => {
    //const obj=
    const content = convertToRaw(event.getCurrentContent())

    this.setState({
        'descripcion': content.blocks[0].text
    });
  };

  setMessage=(mensaje) => {
    this.setState({mensaje});
  }
  //`titulo`, `video`, `tipo`, descripcion
  handleSubmmit = (e) => {
    e.preventDefault();
    if(!this.ITEM){
      this.setMessage("Primero de crear un curso");
      return;
    }
    let { id, titulo, video, servidor, descripcion} =this.state;
    if(titulo.trim() < 2 ) {
      this.setMessage("Escriba el título");
      return;
    }
    if(servidor.trim() < 1 ) {
      this.setMessage("Escriba el tipo de video");
      return;
    }
    if(video.trim() < 1 ) {
      this.setMessage("Escriba el video");
      return;
    }
    if(descripcion.trim() < 1 ) {
      this.setMessage("Escoja el descripción");
      return;
    }
    this.setState({show_loader:true});
      new WebApi().editClase(id, titulo, video, servidor, descripcion, this.ITEM).then(result => {
        setTimeout(() => {
            this.processSetClase(result);
            typeof this.props.handleGetUsers !== "undefined" && this.props.handleGetUsers()
            typeof this.props.hideModal !== "undefined" && this.props.hideModal()
        }, 300)
    })
  }

  processSetClase = (result) => {
    if (result.no_result) {
        return
    }
    this.setState({show_loader:false});
    let { data } = result;
    if (data.result) {
      this.setState({
        id: data.id,
        mensaje: data.message,
      });
      //refrescar las clases
      this.getClases();
    } else {
        this.setMessage(data.message);
    }
  }

  getClase = (id) => {
    /*if(!this.state.id){
      return;
    } */
    this.setState({show_loader:true});
    new WebApi().getClase(id).then(result => {
      setTimeout(() => {
          this.processGetClaseResponse(result);
      }, 300)
    })
  }

  processGetClaseResponse = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        //se redirecciona automaticamente
        data=data.clase;

        this.setState({
          id: data.id,
          titulo: data.titulo,
          video: data.video,
          servidor: data.tipo,
          descripcion: data.descripcion,
        });
    } else {
        this.setMessage(data.message || 'No se pudo obtener la clase');
    }
  }

  getClases = () => {
    if(!this.ITEM){
      return;
    }
    this.setState({show_loader:true});
    new WebApi().getCursosClases(this.ITEM).then(result => {
      setTimeout(() => {
          this.processGetClasesResponse(result);
      }, 300)
    })
  }

  processGetClasesResponse = (result) => {
    this.setState({show_loader:false});
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.result) {
        //se redirecciona automaticamente
        //data=data.data;
        this.setState({
          clases: data.clases,
        });
    } else {
        this.setMessage(data.message || 'Ocurrió un error al intentar guardar la información');
    }
  }

  resetForm = () => {
    this.setState({
      id: 0,
      nombre: "",
      video:"",
      left:'',
      descripcion:'',
      mensaje:"",
    })
  }


}

const mapStateToProps = (state) => {
    return {
        user: state.user_data.user
    }
}
const actions = {};

export default connect(mapStateToProps, actions)(withStyles(useStyles, { withTheme: true })(Capitulos));
