import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import WebApi from '../../../../components/helpers/WebApi';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetAppIcon from '@material-ui/icons/GetApp';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    marginTop:10,
    textAlign: 'center'
  },
  input: {
    display: 'none',
  },
  buttongroup: {
    flexDirection:'row',
  },
  circular: {
    color: "#fff",
    animationDuration: '550ms',
    marginLeft:10
  }
}));

export default function UploadButton(props) {
  const classes = useStyles();
  const [loading, setLoading]= React.useState(false);
  const [messaje, setMessage]=React.useState('');
  const [success, setSuccess]=React.useState(false);
  //const [file, setFile]= React.useState('');
  const setSelectedFile = (file) => {
    if(!file){
      return;
    }
    setLoading(true);
    setMessage('');
    new WebApi().uploadFile(props.item, file).then(result => {
       processResponse(result);
    })
  }

  const processResponse = (result) => {
    setLoading(false);
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.respuesta) {
      props.actualizarArchivo(data.certificado);
      setSuccess(true);
    }
    if(data.mensaje){
      setMessage(data.mensaje);
    }
  }

  const changeHandler = (event) => {
	   setSelectedFile(event.target.files[0]);
	};


  return (
    <div className={classes.root}>
      <div className={classes.buttongroup}>
        <input
          accept="image/*, application/pdf"
          className={classes.input}
          id="contained-button-file"
          type="file"
          onChange={changeHandler}
        />
        <label htmlFor="contained-button-file" >
          <Button variant="contained" color="primary" component="span" size="small">
            Subir Certificado
            {
              loading ?
              <CircularProgress
                  variant="determinate"
                  className={classes.circular}
                  size={20}
                  thickness={4}
                  value={100}

              /> : <PublishIcon />
            }
          </Button>
        </label>
        { props.file !==''?
          <Button
            variant="contained"
            color="secondary"
            component="span"
            size="small"
            style={{marginLeft:10}}
            onClick={()=>window.open(new WebApi()._IMAGES_URL+'certificados/'+props.file,'_blank')}
          >
            Descargar
            <GetAppIcon />
          </Button>: null
        }
      </div>
      { messaje && <Alert severity={ success? "success" : "error"}>{messaje}</Alert> }
    </div>
  );
}
