import React from 'react';
import { withStyles ,makeStyles} from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SubscriptionsIcon from '@material-ui/icons/Subscriptions';
import Avatar from '@material-ui/core/Avatar';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import ClassIcon from '@material-ui/icons/Class';
import { connect } from 'react-redux';
import { setUserData } from '../../components/redux/actions/ActionsUserData';
import MyStorage from '../../components/helpers/MyStorage';
import WebApi from '../../components/helpers/WebApi';

const useStyles = makeStyles((theme) => ({
  link: {
    fontSize:13,
    fontWeight: 'bold',
    marginLeft:20,

  },
  topMargin: {
    marginTop: 10,
  },
  bottomMargin: {
    marginBottom: 10,
  }
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    paddingTop:15,
    paddingBottom:15,
  },

})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function ProfilePopOver(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const {user} = props.user;

  const foto = typeof user.foto !=='undefined' ? user.foto.indexOf('http') != -1 ? user.foto : new WebApi()._IMAGES_URL + user.foto : '';
  //console.log('foto ',foto);
  const classes = useStyles();
  return (
    <div>

      <Avatar alt={props.title} src={foto} onClick={handleClick} />
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          /*<Link href="#" className={classes.link} style={{ color: '#0584d2'}}>
          Ver mi perfil
        </Link>*/
       }
        {
          user.permiso ==='2' || user.permiso==='1' ?
          <StyledMenuItem className={classes.topMargin} onClick={()=>window.open('/dashboard','_self')} >
            <ListItemIcon>
              <DashboardIcon fontSize="small"  style={{ color: '#0584d2' }}/>
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </StyledMenuItem>
          : null
        }

        <StyledMenuItem className={classes.topMargin}>
          <ListItemIcon>
            <AccountBoxIcon fontSize="small" style={{ color: '#0584d2' }}/>
          </ListItemIcon>
          <ListItemText primary="Perfil " />
        </StyledMenuItem>
        <StyledMenuItem className={classes.topMargin}>
          <ListItemIcon>
            <InboxIcon fontSize="small" style={{ color: '#0584d2' }}/>
          </ListItemIcon>
          <ListItemText primary="Mensaje Directo " />
        </StyledMenuItem>
        <StyledMenuItem className={classes.bottomMargin}>
          <ListItemIcon>
            <SubscriptionsIcon fontSize="small"  style={{ color: '#0584d2' }}/>
          </ListItemIcon>
          <ListItemText primary="Mi suscripción" />
        </StyledMenuItem>
        <StyledMenuItem className={classes.bottomMargin}>
          <ListItemIcon>
            <ClassIcon fontSize="small"  style={{ color: '#0584d2' }}/>
          </ListItemIcon>
          <ListItemText primary="Mis cursos" />
        </StyledMenuItem>
        <StyledMenuItem className={classes.bottomMargin}>
          <ListItemIcon>
            <CardMembershipIcon fontSize="small"  style={{ color: '#0584d2' }}/>
          </ListItemIcon>
          <ListItemText primary="Mis certificados" />
        </StyledMenuItem>

        <Link href="#" className={classes.link} style={{ color: '#ff5953'}} onClick={()=> {
          props.setUserData(false);
          new MyStorage().setUserInfo(false);
          window.open('/','_self');
        }}>
          Cerrar Sesión
        </Link>
      </StyledMenu>
    </div>
  );
}


const actions = {
  setUserData
};

const mapStateToProps = function(state) {
  return {
      user: state.user_data
  }
}

export default connect(mapStateToProps, actions)(ProfilePopOver);
