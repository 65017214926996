import React from 'react';
import { FacebookLogin } from 'react-facebook-login-component';
import WebApi from '../../components/helpers/WebApi';
import MyStorage from '../../components/helpers/MyStorage';
import Alert from '../../components/common/Alert';
import { setUserData } from '../../components/redux/actions/ActionsUserData';
import { connect } from 'react-redux';

class FLogin extends React.Component{
  constructor (props, context) {
    super(props, context);
    this.alerta = React.createRef();
    this.login = this.login.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);
    this.processLoginResponse = this.processLoginResponse.bind(this);
  }
  state={
    mensaje:'',
  }

  responseFacebook = (response) => {
    console.log(response);
    if(typeof response.email !=='undefined'){
      //anything else you want to do(save to localStorage)...
      //accessToken: "EAAUTF93YyMkBAA48EogTMulSBgl4rx0UvBHHdX11A4TRnKtfAptFLCqszvN8dQial9xZAx3ZAmBF0boyiZCAX81gHvJZCiv0ZBBWKbNnCf86kVZBhLnOxxiSV6jZBFZAZBU4eiz5ikPcaTinPAsq1ZBZBkrBHSycVABTAEMiGRq2css2jTTVUoJQSKTX1oiDVAefFNGCKvNxaDTzi9lcpaVzRCK7ZCBlgGRAVQUZD"
      //email: "devaray@gmail.com"
      //id: "862337677875517"
      //name: "David Reyes"
      //picture: ''
      this.login(response);
    }
  }

  login = (response) => {
      let {id,name, email, picture, accessToken} = response;
      new WebApi().socialLogin(id,name, email, picture.data.url, accessToken,'facebook').then(result => {
        //setTimeout(() => {
            this.processLoginResponse(result);
        //}, 300)
      })
  }

  processLoginResponse = (result) => {
      let { data } = result;
      if (data.result) {
          //se redirecciona automaticamente
          let user = data.data;
          this.props.setUserData(user);
          user = JSON.stringify(data.data);
          new MyStorage().setUserInfo(user);
      } else {
          alert(data.message);
          //this.setState({mensaje: data.mensaje}, ()=> this.alerta.openAlert())
      }
  }

  render () {
    return (
      <div>
        <FacebookLogin socialId="1428368110700745"
                       language="es_ES"
                       scope="public_profile,email"
                       responseHandler={this.responseFacebook}
                       xfbml={true}
                       fields="id,email,name,picture"
                       version="v2.5"
                       className="facebooklogin"
                       buttonText="Continúa con Facebook"/>
         <Alert ref={this.alerta}
               message={this.state.mensaje}
               title={'Aviso'}
               CancelButtonTitle='Entendido'
         />
      </div>
    );
  }

}

const actions = {
    setUserData
}

//export default FLogin;

export default connect(null, actions)(FLogin);
