import React,{Component} from 'react';
import { connect } from 'react-redux';

import UserForm from './UserForm';
import '../../../App.css';
class Profile extends Component {


  render (){
    let user=this.props.user.user ? this.props.user.user.id: null;
    //const match = this.props.match;
    //match.params.id
    return (
      <div className="container-scroller">
        {/*<!-- partial:partials/_sidebar.html -->*/}

        {/*<!-- partial -->*/}
        <div className="container-fluid page-body-wrapper">
          {/*<!-- partial:partials/_settings-panel.html -->*/}

          {/*<!-- partial -->*/}
          {/*<!-- partial:partials/_navbar.html -->*/}

          {/*<!-- partial -->*/}
          <div className="main-panel">
            <div className="content-wrapper pb-0">
              {/*Content here*/}
              {<UserForm item={user} />}
            </div>
            {/*<!-- content-wrapper ends -->*/}
            {/*<!-- partial:partials/_footer.html -->*/}

            {/*<!-- partial -->*/}
          </div>
          {/*<!-- main-panel ends -->*/}
        </div>
        {/*<!--page-body-wrapper ends-->*/}
      </div>
    )
  }
}


const mapStateToProps = (state) => {
    return {
        user: state.user_data
    }
}
const actions = {};
export default connect(mapStateToProps, actions)(Profile);
//export default Profile;

/*
<!--<a href="#!" className="forgot-password-link">Forgot password?</a> -->
<!--<p className="login-wrapper-footer-text">Don't have an account? <a href="#!" className="text-reset">Register here</a></p>-->
*/
