import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ClassIcon from '@material-ui/icons/Class';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {colorSecondary,colorPrimary} from '../../../components/helpers/Constantes';
import WebApi from '../../../components/helpers/WebApi';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: 20,
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight:'bold',
    color: "#fff",
    backgroundColor: colorPrimary,
  },
  heading_expanded:{
    backgroundColor: colorSecondary,
    color: "#fff",
    fontWeight:'bold',
  },
  container: {
    boxShadow:'none',
    backgroundColor: 'transparent',
  },
}));

export default function Temario(props) {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const [clases, setClases] = React.useState([]);
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };

    const getClase = () => {
      new WebApi().getCursosClases(props.cursoId).then(result => {
        processGetClase(result);
      })
    }

    const processGetClase = (result) => {
      if (result.no_result) {
          return
      }
      let { data } = result;
      if(data.result){
        setClases(data.clases);
      }
    }
     useEffect(() => {
        getClase();

    },[0]);

    return (
      <div className={classes.root}>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className={classes.container}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{color:"#fff"}}/>}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className={expanded? classes.heading_expanded: classes.heading }
          >
            <Typography  >{expanded? 'Ocultar': 'Mostrar' } Temario</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {clases.length>0 && clases.map((text, index) => (
              <ListItem key={index}>
                <ListItemIcon>{<ClassIcon />}</ListItemIcon>
                <ListItemText primary={text.titulo} />
              </ListItem>
            ))}
          </AccordionDetails>
        </Accordion>

      </div>
    );
}
