import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function Alert(props) {
  const [open, setOpen] = React.useState(false);

  const openAlert = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleAction = () => {
    setOpen(false);
    props.action && props.action() ;
  };


  return (
    <div>

      <Dialog
        open={open}
        onClose={handleAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title ? props.title : ''}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message ? props.message : ''}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={handleCancel} color="primary">
            {props.CancelButtonTitle? props.CancelButtonTitle :'Cancelar'}
          </Button>

          {props.ActionButton &&
          <Button onClick={handleAction} color="primary">
            {props.ActionButtonTitle? props.ActionButtonTitle :'Cancelar'}
          </Button>
          }

        </DialogActions>
      </Dialog>
    </div>
  );
}


/*
title
message

CancelButtonTitle string
ActionButton boolean
ActionButtonTitle STRING
action function
*/
