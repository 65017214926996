import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ButtonBase from '@material-ui/core/ButtonBase';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
  sidebar: {
    borderRight: "2px solid #757575",
    backgroundColor: "#606060",
    padding: 10,
  },
  sidebarTitle: {
    color: "#fff",
    fontSize: 18,
    paddingBottom: 10,
    fontWeight: 'bold',
  },
  button: {
    borderColor: "#e0e0e0",
    backgroundColor:"#f7f8f8",
    padding: "10px 20px 10px 20px",
    textAlign: "center",
    fontWeight: "200"
  },
  icon: {
    fontSize: 20,
    marginRight: 10,
  }
}));



function Navigator(props) {

  const classes = useStyles();

  return (
    <React.Fragment>
        <Grid item md={3} className={classes.sidebar}>
            <Typography variant="h3" className={classes.sidebarTitle}>
              Tool Sidebar
            </Typography>
            <Grid container>
              <ButtonBase
                className={classes.button}
                variant="outlined"
                onClick={()=>props.onAdd('text')}
              >
                <AddIcon className={classes.icon} />
                Texto
              </ButtonBase>
            </Grid>


        </Grid>
    </React.Fragment>
  );
}



export default Navigator;
