import React, {Component} from 'react';
import './App.css';

import Routes from './components/Router.js'
//import './App.css';
import {createStore,applyMiddleware} from 'redux';
import {Provider} from 'react-redux';
import ReduxThunk from 'redux-thunk';
import reducers from './components/redux/reducers';


/*function App() {
  return (
    <Inicio/>
  );
}*/

class App extends Component{
  render() {
    console.disableYellowBox = true;
    const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
    return (
      <Provider store={store} >
        <Routes />
      </Provider>
    );
  }
}

export default App;
