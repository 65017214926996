import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import WebApi from '../../../components/helpers/WebApi';
import Spinner from '../../../components/common/Spinner';
import fondo from '../../../assets/images/fondo.jpg';

import {colorPrimary,colorTerciario} from '../../../components/helpers/Constantes';
import Main from '../Blog';


const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
  columns: {
    padding: '20px !important',
  },
  imagenContainer : {
    height: 300,
	  overflow: 'hidden',
    marginBottom: 20
  },
  imagen:{
    margin: 'auto',
    display:'block',
    width:'100%',
  },
  content:{
    paddingBottom:40,
  },

}));

function Instructor(props) {
  const classes = useStyles();

  /* useEffect(() => {
      getCertificado();
  },[0]); */
  return (
      <Main content=
          {
            <React.Fragment>
              <Grid container spacing={3} wrap="nowrap" className={classes.content} >
                <Grid  item sm  className={classes.columns}>
                <React.Fragment>
                <div className={classes.imagenContainer}>
                <img src={fondo}
                      alt="Certificado activo"
                      sizes="(min-width: 600px) 200px, 50vw"
                      className={classes.imagen}
                />
                </div>
                <Typography variant="h5" gutterBottom style={{textAlign:'center'}}>
                    Muy pronto Tú también podrás
                      <Typography variant="h5" gutterBottom style={{fontWeight:'bold'}}> ser un Instructor </Typography>
                      <Typography variant="h5" gutterBottom style={{fontWeight:'bold'}} className={'logo-text'}> Geek Learning</Typography>
                </Typography>

                </React.Fragment>

                </Grid>
              </Grid>
            </React.Fragment>
          }
      />
  );
}


export default Instructor;
