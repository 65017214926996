import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleIcon from '@material-ui/icons/People';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual';
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import SchoolIcon from '@material-ui/icons/School';
import CropFreeIcon from '@material-ui/icons/CropFree';

const categories = [
  {
    id: 'Academia',
    children: [
      { id: 'Usuarios', icon: <PeopleIcon />, url:  "/dashboard/users" , active: activeRoute( '/dashboard/users') },
      { id: 'Cursos', icon: <DnsRoundedIcon />,url: '/dashboard/cursos' , active: activeRoute( '/dashboard/cursos') },
      { id: 'Inscritos', icon: <PermMediaOutlinedIcon />,url: '' },
      { id: 'Certificados Únicos', icon: <PublicIcon /> ,url: '/dashboard/certificados'},
      { id: 'Formas de Pago', icon: <SettingsEthernetIcon />,url: '' },
      { id: 'Roles', icon: <SettingsInputComponentIcon />,url: '' },
      { id: 'Educadores', icon: <SchoolIcon />,url: '' },
      { id: 'Herramientas', icon: <CropFreeIcon />, url: '/dashboard/tools', active: activeRoute('/dashboard/tools') },
    ],
  },
  {
    id: 'App Móvil',
    children: [
      { id: 'Analytics', icon: <SettingsIcon /> },
      { id: 'Informes', icon: <TimerIcon /> },
      { id: 'Test Lab', icon: <PhonelinkSetupIcon /> },
    ],
  },
  {
    id: 'Sin Intermediarios',
    children: [
      { id: 'Analytics', icon: <SettingsIcon /> },
      { id: 'Informes', icon: <TimerIcon /> },
      { id: 'Test Lab', icon: <PhonelinkSetupIcon /> },
    ],
  },
];

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 20,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

function Navigator(props) {
  const { classes, ...other } = props;

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
           Geek Admin
        </ListItem>
        <ListItem className={clsx(classes.item, classes.itemCategory)}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classes.itemPrimary,
            }}
            onClick={(event) => handleOpenUrl('/dashboard')}
          >
            Inicio
          </ListItemText>
        </ListItem>
        {categories.map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, url,active }) => (
              <ListItem
                key={childId}
                button
                className={clsx(classes.item, active && classes.itemActiveItem)}
                onClick={(event) => handleOpenUrl(url)}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

const handleOpenUrl =(url, blank)=> window.open(url, blank ?  "_blank" : '_self');

// verifies if routeName is the one active (in browser input)
function activeRoute(routeName) {
  return window.location.href.indexOf(routeName) > -1 ? true : false;
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);
