 import React,{Component} from 'react'
 import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
 import Loader from 'react-loader-spinner';
 //https://www.npmjs.com/package/react-loader-spinner

  export default class Spinner extends Component {
  //other logic
    render() {
     return(
     <div className="col-sm-12 spinner_class"
      style={{textAlign:"center", marginTop:20, marginBottom:20, width: '100%'}}
      >
      <Loader
         type="Circles"
         color="#00BFFF"
         height={70}
         width={70}
         //timeout={3000} //3 secs
         visible={this.props.visible}
      />
      </div>
     );
    }
 }
