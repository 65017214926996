import {
    API
} from "../actions/types";


const INITIAL_STATE = {
    api: 'academia',
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case API:
            return {
                ...state,
                api: action.payload
            }
        default:
            return state;
    }
}
