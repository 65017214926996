import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import SendIcon from '@material-ui/icons/Send';
import Link from '@material-ui/core/Link';
import WebApi from '../../../components/helpers/WebApi';
import {colorPrimary,colorTerciario, colorSecondary, URL_APP, IMPUESTO} from '../../../components/helpers/Constantes';
import { connect } from 'react-redux';
import axios from 'axios';
import PropTypes from 'prop-types'


const useStyles = makeStyles((theme) => ({
  button: {

    width: '100%',
    backgroundColor: colorTerciario,
    height: '45px !important',
    fontWeight: 'bold',
    "&:hover": {
      background: colorSecondary,
    },
  }
}));


//const posts = [post1, post2, post3];
const posts = [];

function BotonDePago(props) {
  const classes = useStyles();
  //const match = props.match;
  const [curso, setCurso] = React.useState([]);
  //const prizeWithTax= Math.round(props.amount*1.12*100)/100;

  let access_token = 'z1lXorWXCmzrT2QTAZwgWqGPs9nb3oF6ORRqFRPP4h85Qf7XZbJgVgf4jaWRLmtdRsX0jZjvK_2cw8FhaVdaOrlkTgn5BXY8WQnYn2uzQIzu-oLMp2QM2P3neITBTDkeZ7QgiHUhYnWRrG8-VdDqBRMcYVhVgSDwyShO96Gw68By_ihq39m9sTwvndrzGlfwklMoomUKLtQzIM6tSKHESBFNdbvLKMsq-CkbAPJ1jqjitVEjig7LYNWCov5nF_qsteWSr07nnv8uemHmbnAfhPBsEZ104pSGZP_0WM6Kxh9tsPtIyMhTVFFUoJ5FwDvDhtVfZUTu9xBCujMKi-zA5vBfcqo';
  let url= URL_APP;
  let  pago = {
              amount:  props.amountWithTax*100,	//Integer 		Valor total por cobrar al cliente
              amountWithoutTax: 0, // 	Integer 	X 	Valor del subtotal de los productos que no cobran impuestos. Solo se usa con tipo de facturación tarifa 0% o mixta.
              amountWithTax: props.amount*100,  // 	Integer 	X 	Valor del subtotal de los productos que cobran impuestos sin sumar el valor de los impuestos. Solo se usa con tipo de facturación con IVA o mixta.
              tax: props.amount*IMPUESTO,    //	Integer 	X 	Valor total de los impuestos. Solo se usa con tipo de facturación con IVA o mixta.
              //service: 0,  //	Integer 	X 	Valor del servicio
              //tip: 0, // 	Integer 	X 	Valor de la propina
              currency: 'USD', 	//String 	X 	Moneda con la que se cobra, ejemplo “USD”.
              clientTransactionId: props.product_Id, // 	String 		Identificador de la transacción en la aplicación del cliente (String asignado por tu empresa  tu plataforma). Es un parámetro que sirve de identificación para consulta o reverso de la transacción.
              responseUrl:  url+'curso/'+props.alias,	// String 		Url de respuesta donde PayPhone enviara al cliente después de finalizada la transacción
              cancellationUrl: url+'pagocancelado', 	//String 	X 	Url de retorno cuando el cliente cancela el proceso de pago.
              //phoneNumber: '', 	//String 	X 	Número de teléfono del usuario que realiza el pago.
              //email: '',      //	String 	X 	Correo electrónico del usuario que realiza el pago.
              //documentId: '', //	String 	X 	Documento de identidad del usuario que realiza el pago. (Número de cédula o pasaporte)
              StoreId:'e40ec9b1-779a-43b6-9dfe-55e3316f70fd', //	String 	X 	Id del Store que va a cobrar, Este valor solo se envía cuando se manejan diferentes tiendas o sucursales, si no se usan diferentes tiendas se puede omitir. Para especificar que tienda hace el cobro, el storeID se obtiene desde la página web de PayPhone Developer, en la sección solicitud de compañía, columna Acciones, Listado de tiendas.
              Reference: props.productName, //	String 	X 	Referencia de la transacción, por ejemplo “Venta Zapatos Azules”
              //AditionalData: '', 	//String 	X 	Datos adicionales que quieras guardar de la transacción.
              //OptionalParameter: '',  //	String 	X 	Parámetro opcional a tu disposición.
              //transferTo: '' // 	String 	X 	Parámetro para pagos compartidos. En el siguiente enlace puedes revisar como hacer pagos compartidos.
  }

  const enviarPagos = async () =>  {

    let response= await axios({
          method: 'post',
          url: 'https://pay.payphonetodoesposible.com/api/button/Prepare',
          data: pago,
          timeout: 15000,
          headers: {
            'Authorization': `Bearer ${access_token}`
          }
      }).then((response) => {
        //console.log(response);
        if (response.no_result) {
            alert('Algo salió mal');
            return;
        }
        let { data } = response;
        window.location.href=data.payWithCard;

      })
      .catch( (error) => {
        console.error(error);
        alert("Algo salió mal");
      });
   }


  //alert(alias);
  return (
    <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        endIcon={<SendIcon/>}
        onClick={()=>enviarPagos()}
      >
        Compra ahora
      </Button>
        <Grid style={{marginTop:30, }}>
       <Link href="#" onClick={()=>window.open('/membresia')} style={{textAlign:'center', fontWeight:'bold'}}>
         Obtén hasta el 100% de descuento en cursos con una membresía PRO.
       </Link>
       </Grid>
    </React.Fragment>
  );
}



const actions = {

};

const mapStateToProps = function(state) {
  return {
      user: state.user_data.user
  }
}

export default connect(mapStateToProps, actions)(BotonDePago);


BotonDePago.propTypes = {
  amount: PropTypes.string,
  product_Id: PropTypes.string,
  productName:  PropTypes.string,
  amountWithTax: PropTypes.number,
  alias: PropTypes.string,
}
