import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import Moment from 'react-moment';
import 'moment/locale/es';
import 'moment-timezone';
import WebApi from '../../../components/helpers/WebApi';


const useStyles = makeStyles({
  card: {
    display: 'flex',
  },
  media: {
    height: 140,
  },
  cardDetails: {
    flex: 1,
  },
  cardMedia: {
    width: 160,
  },
});

export default function FeaturedPost(props) {
  const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } });
  const classes = useStyles();
  const { post } = props;
  //console.log('post: ',post);
  const openNotice=(id)=>{
    window.open('noticia/'+id,'_self')
  }

  return (
    <Grid item xs={12} md={6}>
      <CardActionArea component="a" href={'noticia/'+post.id}>
        <Card className={classes.card}>
          <div className={classes.cardDetails}>
          <Hidden mdUp>
            <CardMedia
              className={classes.media}
              image={post.jetpack_featured_media_url}
              title={post.title && new WebApi().descodificarEntidad(post.title.rendered)}
            />
            </Hidden>
            <CardContent>
              <Typography component="h2" variant="h5">
                {post.title && new WebApi().descodificarEntidad(post.title.rendered)}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">
                <Moment format="DD - MMMM - YYYY" locale={'es'} tz={'America/Guayaquil'}>{post.date}</Moment>
              </Typography>
              <Typography variant="subtitle1" paragraph>
                { //post.excerpt && renderHTML(post.excerpt.rendered )
                }
              </Typography>
              <Typography variant="subtitle1" color="primary" onClick={()=>openNotice(post.id)}>
                Continuar leyendo...
              </Typography>
            </CardContent>
          </div>
          <Hidden xsDown>
            <CardMedia className={classes.cardMedia} image={post.jetpack_featured_media_url} title={post.title && new WebApi().descodificarEntidad(post.title.rendered)} />
          </Hidden>
        </Card>
      </CardActionArea>
    </Grid>
  );
}

FeaturedPost.propTypes = {
  post: PropTypes.object,
};
