import {
    combineReducers
} from 'redux';
// import HomeReducer from './ReducerHome';
// import TabSelectReducer from './ReducerSelectedTab';
// import ReducerLogin from './ReducerLogin';
// import ReducerSignup from './ReducerSignup'

import ReducerUserData from './ReducerUserData';
import ReducerSelectedTab from './ReducerSelectedTab';

export default combineReducers({
    user_data: ReducerUserData,
    api: ReducerSelectedTab,
});
