import React,{useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Beneficios from './Beneficios';
import ExternalCourse from './ExternalCourse';

import {mainFeaturedPost, sections} from '../../../components/helpers/Constantes';
import Spinner from '../../../components/common/Spinner';
import WebApi from '../../../components/helpers/WebApi';
import Main from '../Main';


const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
    justifyContent: "center"
  },
}));


export default function Inicio() {
  const classes = useStyles();
  //const [state, setValue] = React.useState({ featuredPosts: [],  page:1,   show_loader: false });
  const [featuredPosts, setFeatured] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [show_loader, setShow_loader]= React.useState(false);
  React.useEffect(() => {getNoticias()}, [page])

  const next_page =()=>{
    setPage(prevState => { return prevState +1});
  }
  const getNoticias = () => {
    setShow_loader(true);
    new WebApi().getNoticias(page).then(result => {
      processGetNoticias(result);
    })
  }

  const processGetNoticias = (result) => {
    setShow_loader(false);
    if (result.no_result) {
        return
    }
    let { data } = result;
    if (data.length>0) {
      setFeatured([...featuredPosts, ...data]);
      console.log(featuredPosts);
      /*  if(state.featuredPosts.length===0){
        } else {
            setFeatured(prev =>[...prev, data]);
        }*/
    }

  }

   useEffect(() => {
      getNoticias();
  },[0]);

  return (
    <Main content=
      {
        <React.Fragment>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Container maxWidth="lg">
            <Grid container spacing={5} className={classes.mainGrid}>
                <Beneficios />
            </Grid>

            <ExternalCourse/>

            <Typography variant="h3" component="h3" align='center' style={{fontSize:32, fontWeight: 'bold',marginTop:40}}>
              Entérate de nuestras novedades
            </Typography>
            <Divider light style={{marginBottom:30}}/>
            <Grid container spacing={4} style={{paddingLeft:10, paddingRight:10}}>
              {typeof featuredPosts !=='undefined'  && featuredPosts.map((post,i) => (
                <FeaturedPost key={i} post={post} />
              ))}
            </Grid>
            <Spinner visible={show_loader}/>
            <Button variant="outlined" color="primary" onClick={()=>next_page()}>
              Más noticias
            </Button>
          </Container>
        </React.Fragment>
      }
    />
  );
}
